import type { StoredObject } from '@nexdynamic/squeegee-common';

export type DatabaseNames =
    | `squeegee_dexie_${string}`
    | 'localStorageWrapper'
    | `squeegee_audit_dexie_${string}`
    | `squeegee_balance_dexie_${string}`;
export abstract class DataStoreManager<T = StoredObject> {
    public constructor(
        public readonly name: DatabaseNames,
        public readonly noCache = false,
        private readonly _storedObjectMemoryOptimiser?: (storedObject: T) => T
    ) {}
    private collectionData: {
        [id: string]: T | undefined;
    } = {};
    public abstract initialise(): Promise<void>;
    public abstract getFromDataStore(id: string): Promise<T | undefined>;
    public abstract writeAllToDataStore(storedObjects: Readonly<Array<T>>): Promise<void>;
    public abstract deleteAllFromDataStore(ids: Readonly<Array<string>>): Promise<boolean>;
    public abstract removeAllDataStoresFromDisk(): Promise<void>;
    storeInCollectionData(key: string, storedObject: T) {
        if (this.noCache) return;
        if (this._storedObjectMemoryOptimiser) {
            storedObject = this._storedObjectMemoryOptimiser(storedObject);
        }
        const existing: any = this.collectionData[key];

        if (existing === storedObject) return;

        if (!this.collectionData[key]) {
            this.collectionData[key] = storedObject;
            return;
        }

        const keys = Object.keys(existing);
        for (let i = 0; i < keys.length; i++) {
            delete existing[keys[i]];
        }
        Object.assign(existing, storedObject);
    }

    getFromCollectionData(key: string) {
        return this.collectionData[key];
    }
    deleteFromCollectionData(key: string) {
        delete this.collectionData[key];
    }
    resetCollectionData() {
        this.collectionData = {};
    }
    getCollectionDataArr(): Array<T> {
        return Object.values(this.collectionData) as Array<T>;
    }
    getCollectionData() {
        return this.collectionData;
    }
    getCollectionDataKeys(): Array<string> {
        return Object.keys(this.collectionData);
    }
}
