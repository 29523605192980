import type { SmtpSettings, TranslationKey } from '@nexdynamic/squeegee-common';
import { Account, Notification } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { LoaderEvent } from '../Events/LoaderEvent';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { Utilities } from '../Utilities';
import { CustomDialog } from './CustomDialog';
import { Prompt } from './Prompt';
import { TextDialog } from './TextDialog';

export class SmtpDialog extends CustomDialog<void> {
    protected customSmtp: boolean = ApplicationState.account.emailAPI === 'custom-smtp';

    protected username: string = (ApplicationState.account.smtp && ApplicationState.account.smtp.userName) || '';
    protected password: string = ApplicationState.stateFlags.devMode
        ? (ApplicationState.account.smtp && ApplicationState.account.smtp.password) || ''
        : '';

    protected _host: string = (ApplicationState.account.smtp && ApplicationState.account.smtp.host) || '';

    protected replyToEmail = ApplicationState.account.customReplyTo || '';
    protected accountEmail = ApplicationState.dataEmail || '';
    protected get host() {
        return this._host || this.getDefaultHost();
    }
    protected set host(value: string) {
        this._host = value;
    }

    protected _port: number | undefined = (ApplicationState.account.smtp && ApplicationState.account.smtp.port) || undefined;
    protected get port() {
        return this._port || this.getDefaultPort();
    }
    protected set port(value: number | undefined) {
        this._port = value;
    }

    private _fromEmail: string = <string>(ApplicationState.account.smtp && ApplicationState.account.smtp.fromEmail);
    protected get fromEmail() {
        return this._fromEmail || this.username;
    }
    protected set fromEmail(email: string) {
        this._fromEmail = email;
    }

    protected lastTestNotification: Notification | undefined;

    protected get lastTestNotificationDetails() {
        return ((this.lastTestNotification && this.lastTestNotification.statusHistory) || [])
            .map(h => `${h.status}\n${moment(h.timestamp, 'x').format('lll')}\n${h.data}`)
            .join('\n\n');
    }

    private DEFAULT_TEST_MESSAGE = 'This is a test message to ensure that SMTP configuration is set up correctly.';

    private readonly _currentSettings: string;
    constructor() {
        super('smtpDialog', './SmtpDialog.html', ApplicationState.localise('general.save'), {
            cssClass: 'text-dialog',
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
        });

        this._currentSettings = this.getSettingsAsString();
    }

    private getSettingsAsString(settings = (ApplicationState.account.smtp || {}) as SmtpSettings) {
        return [
            this.replyToEmail,
            ApplicationState.account.emailAPI || '',
            settings.userName || '',
            settings.password || '',
            settings.fromEmail || '',
            settings.host || '',
            settings.port || '',
        ].join('');
    }

    private get settingsUpdated() {
        const settings = {
            userName: this.username,
            password: this.password || (ApplicationState.account.smtp && ApplicationState.account.smtp.password) || '',
            fromEmail: this.fromEmail,
            host: this.host,
            port: Number(this.port),
        } as SmtpSettings;
        return this._currentSettings !== this.getSettingsAsString(settings);
    }
    protected async sendTestEmail() {
        if ((await this.onValidate()) !== true) return;

        if (this.settingsUpdated) {
            if (
                !(await new Prompt(
                    'Save Settings' as TranslationKey,
                    'You have changed some settings, you must save before you can send a test email. Would you like to save these settings or cancel?' as TranslationKey,
                    { okLabel: 'general.save' }
                ).show())
            ) {
                return;
            }

            this.delegateOk(false);
        }
        const textDialog = new TextDialog(
            'general.email',
            'customers.email-label',
            '',
            '',
            value => (!!value && Account.EMAIL_REGEX.test(value)) || <any>'A valid email is required',
            false,
            'email',
            'general.send'
        );

        const email = await textDialog.show();
        if (!textDialog.cancelled) {
            new LoaderEvent(true);
            try {
                const description = `Squeegee SMTP Test - ${moment().format()}`;
                const name = ApplicationState.account.businessName || ApplicationState.account.name;
                const notification = new Notification({
                    description,
                    address: email,
                    addressee: name,
                    type: 'Email',
                    sender: name,
                    message: this.DEFAULT_TEST_MESSAGE,
                });

                this.lastTestNotification = notification;

                const result = await Api.post<Notification>(Api.apiEndpoint, '/api/send-notification', notification);
                this.lastTestNotification = result && result.data;
            } catch (error) {
                new NotifyUserMessage('problem-running-database-SMTP');
            } finally {
                new LoaderEvent(false);
            }
        }
    }

    getDefaultHost(): string {
        if (/.*?@yahoo\.com$/.test(this.username) || /.*?@yahoo\.co.uk$/.test(this.username)) return 'smtp.mail.yahoo.com';
        if (/.*?@gmail\.com$/.test(this.username) || /.*?@googlemail\.com$/.test(this.username)) return 'smtp.gmail.com';
        if (/.*?@outlook\.com$/.test(this.username) || /.*?@hotmail\.com$/.test(this.username)) return 'smtp-mail.outlook.com';
        if (/.*?@btinternet\.com$/.test(this.username)) return 'mail.btinternet.com';
        return '';
    }

    getDefaultPort(): number | undefined {
        if (/.*?@yahoo\.com$/.test(this.username) || /.*?@yahoo\.co.uk$/.test(this.username)) return 587;
        if (/.*?@gmail\.com$/.test(this.username) || /.*?@googlemail\.com$/.test(this.username)) return 587;
        if (/.*?@outlook\.com$/.test(this.username) || /.*?@hotmail\.com$/.test(this.username)) return 587;
        if (/.*?@btinternet\.com$/.test(this.username)) return 587;
    }

    protected errors: TranslationKey;
    public async onValidate(): Promise<true | TranslationKey> {
        const errors: Array<TranslationKey> = [];
        if (!this.customSmtp) {
            if (this.replyToEmail && !Account.EMAIL_REGEX.test(this.replyToEmail)) errors.push('email.reply-must-be-valid-email-address');
        } else {
            if (!this.username) errors.push('smtp.username-is-required');
            if (!this.password && (!ApplicationState.account.smtp || !ApplicationState.account.smtp.password))
                errors.push('smtp.password-is-required');
            if (this.fromEmail && !Account.EMAIL_REGEX.test(this.fromEmail)) errors.push('email.from-must-be-a-valid-email');
            if (!this.host) errors.push('smtp.host-is-required');
            if (!this.port) errors.push('smtp.port-is-required');
            else if (isNaN(Number(this.port))) errors.push('smtp.port-must-be-a-number');
        }

        if (!errors.length) return true;

        this.errors = Utilities.localiseList(errors);

        new NotifyUserMessage(this.errors, undefined, 15000);

        return this.errors;
    }

    public delegateOk = async (close = true) => {
        if ((await this.onValidate()) !== true) return;

        if (!this.customSmtp) {
            ApplicationState.account.emailAPI = null;
            ApplicationState.account.smtp = <any>null;
            ApplicationState.account.customReplyTo =
                this.replyToEmail || (RethinkDbAuthClient.session && RethinkDbAuthClient.session.email) || '';
        } else {
            ApplicationState.account.smtp = {
                userName: this.username,
                password: this.password || (ApplicationState.account.smtp && ApplicationState.account.smtp.password) || '',
                fromEmail: this.fromEmail,
                host: this.host,
                port: Number(this.port),
            };
            ApplicationState.account.emailAPI = 'custom-smtp';
        }
        await ApplicationState.save();
        if (close && !this.devMode) this.ok();
        else if (this.settingsUpdated) new NotifyUserMessage('notifications.settings-saved');
    };
}
