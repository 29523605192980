import type { Expense, PaymentAccount } from '@nexdynamic/squeegee-common';
import { TagType } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { TextDialog } from '../../Dialogs/TextDialog';
import { ExpenseService } from '../../Expenses/ExpenseService';
import { Logger } from '../../Logger';
import { SelectTagsDialog } from '../../Tags/Components/SelectTagsDialog';
export class ExpenseFormCustomElement {
    @bindable() expense: Expense;

    @bindable() paymentAccount?: PaymentAccount;
    @bindable() onSubmit: (expense: Expense) => void;

    protected failedValidation = false;
    protected expenseDescInput: HTMLInputElement;
    protected expenseDateField: string;
    protected currencySymbol = ApplicationState.currencySymbol();

    protected isPaid = false;

    protected applicationState = ApplicationState;

    @computedFrom('expense.amount', 'expense.taxRate')
    get expenseTaxInfo() {
        this.updateExpense();
        const { subtotal, totalTax } = this.expense;
        return { subtotal, totalTax };
    }

    protected updateExpense() {
        ExpenseService.updateTotals(this.expense);
    }

    protected newDatePicked = () => {
        this.expense.date = new Date(this.expenseDateField).toISOString();
        return;
    };

    @computedFrom('expense.taxEnabled', 'expense.taxRate', 'applicationState.account.expenseSettings.taxRate')
    protected get activeTaxRate() {
        let taxRate;
        if (this.expense.taxEnabled === undefined || this.expense.taxRate === undefined) {
            taxRate = ApplicationState.account.expenseSettings?.taxRate || 0;
        } else {
            taxRate = this.expense.taxRate;
        }
        return taxRate;
    }

    @computedFrom('applicationState.account.expenseSettings.taxEnabled')
    protected get globalTaxEnabled() {
        return ApplicationState.account.expenseSettings?.taxEnabled;
    }

    @computedFrom('applicationState.account.expenseSettings.taxRate')
    protected get globalTaxRate() {
        return ApplicationState.account.expenseSettings?.taxRate || 0;
    }
    @computedFrom('applicationState.hasUltimateOrAbove')
    protected get hasUltimateOrAbove() {
        return ApplicationState.hasUltimateOrAbove;
    }

    @computedFrom('applicationState.hasAdvancedOrAbove')
    protected get balanceEnabled() {
        return ApplicationState.features.balance;
    }

    protected hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;

    protected setTaxEnabled(taxEnabled?: boolean) {
        this.expense.taxEnabled = taxEnabled;
        if (!taxEnabled) {
            this.expense.taxRate = undefined;
        }
        this.updateExpense();
    }

    protected setIsPaid(isPaid: boolean) {
        this.isPaid = isPaid;
        if (isPaid) {
            this.expense.paymentDate = this.expense.date || moment().format('YYYY-MM-DD');
        } else {
            this.expense.paymentDate = undefined;
        }
    }

    protected attached() {
        this.isPaid = !!this.expense.paymentDate;
        if (this.expense.taxEnabled !== undefined) return;
        this.expense.taxEnabled = this.globalTaxEnabled;
        this.expense.taxRate = this.globalTaxEnabled ? this.globalTaxRate : undefined;
    }

    @computedFrom('applicationState.account.expenseSettings.taxRate', 'expense.taxRate', 'globalTaxEnabled')
    public get taxRateIsGlobal() {
        return this.applicationState.account?.expenseSettings?.taxRate === this.expense?.taxRate;
    }

    public resetTaxRate() {
        this.expense.taxRate = ApplicationState.account?.expenseSettings?.taxRate;
        this.updateExpense();
    }

    protected async addCategory(event: Event) {
        const dialog = new SelectTagsDialog(
            this.expense.category ? [this.expense.category] : [],
            TagType.EXPENSE_CATEGORY,
            1,
            1,
            'expense'
        );
        const categories = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            this.expense.category = categories[0];
        }
        if (event) event.stopPropagation();
    }

    public validateExpense = () => {
        if (ExpenseService.validateExpenseAndNotifyUI(this.expense).length) {
            this.failedValidation = true;
            return false;
        }
        this.failedValidation = false;
        return true;
    };

    public async selectTaxRate() {
        const dialog = new TextDialog(
            'dialogs.settings-expense-tax-rate-title',
            'dialogs.settings-expense-tax-rate-label',
            (this.expense.taxRate || 0).toString(),
            'dialogs.settings-expense-tax-rate-title',
            value => (isNaN(parseFloat(value)) || parseFloat(value) <= 0 ? 'validation.tax-rate-invalid' : true),
            false,
            'number'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) this.expense.taxRate = parseFloat(result);
        this.updateExpense();
    }

    protected submit = () => {
        try {
            if (this.validateExpense()) {
                this.onSubmit(this.expense);
            }
        } catch (error) {
            Logger.error('Error submitting expense form', error);
        }
    };
}
