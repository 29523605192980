import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { randomInteger, wait } from '@nexdynamic/squeegee-common';
import dexie from 'dexie';
import { ApplicationEnvironment } from '../ApplicationEnvironment';
import { Prompt } from '../Dialogs/Prompt';
import { BGLoaderEvent } from '../Events/BGLoaderEvent';
import { LoaderEvent } from '../Events/LoaderEvent';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { DataStoreManager } from './DataStoreManager';

import { animate } from '../Utilities';
import type { DatabaseNames } from './DataStoreManager';

export class DexieDataStoreManager<T> extends DataStoreManager<T> {
    private _database: dexie & { squeegee: dexie.Table<T, string> };
    public paused: boolean;
    private _resumeTimeout: any;
    public constructor(
        name: DatabaseNames,
        private readonly _schemaVersions: Array<string>,
        noCache = false,
        _storedObjectMemoryOptimiser?: (storedObject: T) => T
    ) {
        super(name, noCache, _storedObjectMemoryOptimiser);
    }
    public async initialise() {
        Logger.info('Initialising DexieDataStoreManager');
        await this.createDatabaseConnection();
        await this.populateCollectionDataFromDexie();
        document.addEventListener(
            'resume',
            () => {
                clearTimeout(this._resumeTimeout);
                this._resumeTimeout = setTimeout(() => {
                    if (!this.paused) return;

                    Logger.info('Resuming data writes due to resume event');
                    this.paused = false;
                }, 500);
            },
            false
        );
        document.addEventListener(
            'pause',
            () => {
                clearTimeout(this._resumeTimeout);
                if (this.paused) return;

                Logger.info('Pausing data writes due to pause event');
                this.paused = true;
            },
            false
        );
    }

    private async createDatabaseConnection(freeWritingResolver = false) {
        try {
            if (this._database) {
                try {
                    await this._database.close();
                } catch (error) {
                    Logger.error('Failed to close open database.', error);
                }
                await wait(500);
            }
            this._database = <dexie & { squeegee: dexie.Table<T, string> }>new dexie(this.name, { chromeTransactionDurability: 'relaxed' });

            for (const [idx, versionSchema] of this._schemaVersions.entries()) {
                await this._database.version(idx + 1).stores({ squeegee: versionSchema });
            }

            if (!this._database.isOpen()) {
                await this._database.open();
                // WTF Bug in webkit, if we don't wait a bit, the database is not ready to be written to and locks up.
                // Bug is repoduceable when using query string session
                if (GlobalFlags.isSafari) await wait(500);
            }
            if (freeWritingResolver && this._writingResolver) this._writingResolver();

            if (this._recoveryData && this._recoveryData.length) {
                Logger.error('Recovering ' + this._recoveryData.length + ' objects that failed to write');

                await this.writeAllToDataStore(this._recoveryData);
            }
        } catch (error) {
            Logger.error('Failed to initialise the local indexdb database.', error);

            if (GlobalFlags.isAppleMobileDevice) new NotifyUserMessage('failed.loading-local-database-iOS');
            else new NotifyUserMessage('failed.loading-local-database-general');
            await wait(10000);
            new LoaderEvent(true);
            document.location.reload();
        }
    }

    public async each(callback: (value: T) => void) {
        return await this._database.squeegee.each(callback);
    }

    private async populateCollectionDataFromDexie() {
        Logger.info('Populating collection data from Dexie');
        if (this.noCache) return;
        this.resetCollectionData();
        const totalRecordsCount = await this._database.squeegee.count();
        let loadedRecordsCount = 0;
        const step = randomInteger(4950, 5050);

        await this._database.squeegee.each((storedObject: T) => {
            const key = (storedObject as any)._id;
            if (!key) return;
            this.storeInCollectionData(key, storedObject);
            loadedRecordsCount++;
            if (loadedRecordsCount % step !== 0) return;
            BGLoaderEvent.emit(
                true,
                `Loading ${loadedRecordsCount} of ${totalRecordsCount} records...`,
                (loadedRecordsCount / totalRecordsCount) * 100
            );
        });

        await animate();
    }

    public async count() {
        return await this._database.squeegee.count();
    }

    public async getFromDataStore(id: string) {
        return await this._database.squeegee.get(id);
    }

    public async bulkGet(ids: Array<string>) {
        return await this._database.squeegee.bulkGet(ids);
    }

    public async writeAllToDataStore(storedObjects: Readonly<Array<T>>) {
        await this.performDataWrite(storedObjects);
    }
    private writeCount = 0;
    private _writing: Promise<any>;

    private _writingResolver: (value: void) => void;

    private _writeQ: Array<T> = [];
    private _recoveryData?: Array<T>;

    public async performDataWrite(newStoredObjects: Readonly<Array<T>>) {
        if (!newStoredObjects.length) return;
        let all: Array<T> = [];
        let batch: Array<T> = [];
        try {
            for (const o of newStoredObjects) this._writeQ.push(o);

            if (this._writing) {
                await this._writing;
            }

            this._writing = new Promise<void>(resolve => {
                this._writingResolver = resolve;
            });

            all = this._writeQ.splice(0, this._writeQ.length);
            let count = 0;
            while ((batch = all.splice(0, ApplicationEnvironment.dataBatchSize)).length) {
                if (count > 6) {
                    count = 0;
                    await wait(1);
                }
                count++;
                while (this.paused) await wait(250);
                const innerBatch = batch.slice(0);
                await new Promise<void>(async (resolve, reject) => {
                    try {
                        if (!this._database.isOpen()) {
                            try {
                                await this._database.open();
                            } catch (error) {
                                throw new Error('Unable to open a local database.');
                            }
                        }
                        this.writeCount += innerBatch.length;
                        let count = 4;
                        while (count--) {
                            try {
                                //await this._database.transaction('rw!', this._database.squeegee, async () => {
                                await this._database.squeegee.bulkPut(innerBatch);
                                //});
                                count = 0;
                            } catch (ex) {
                                Logger.info('Failed to write to database, retrying...', ex);
                                if (count === 0) throw ex;
                                await this.createDatabaseConnection();
                                await wait(500);
                            }
                        }
                        return resolve();
                    } catch (error) {
                        return reject(error);
                    }
                });
            }
        } catch (error) {
            Logger.error(`Unable to update ${newStoredObjects.length} items. ${this.writeCount}`, {
                error,
                objectsToUpdate: newStoredObjects,
            });
            new LoaderEvent(false);
            const dialog = new Prompt(
                'Unable to save data!' as TranslationKey,
                `Your device failed to save your changes, please check your free space and retry.'
                    Error details: ${error.message}` as TranslationKey,
                { cancelLabel: 'Cancel' as TranslationKey, okLabel: 'Retry' as TranslationKey }
            );
            await dialog.show();

            if (dialog.cancelled) throw error;

            const retryItems = all.concat(batch);
            if (retryItems.length) this._recoveryData = retryItems.slice();

            await this.createDatabaseConnection(true);
        } finally {
            if (this._writingResolver) this._writingResolver();
        }
    }

    public async deleteAllFromDataStore(ids: Readonly<Array<string>>) {
        try {
            if (!this._database.isOpen()) return false;

            for (const id of ids) this.deleteFromCollectionData(id);
            await this._database.squeegee.bulkDelete(ids as Array<string>);
            return true;
        } catch (error) {
            Logger.error('Failed to clear down the the local database.', error);
            return false;
        }
    }

    public async removeAllDataStoresFromDisk() {
        Logger.info(`Removing all data stores for: ${this.name} from disk`);
        await this._database.delete();
        await this.initialise();
    }
}
