import type {
    Attachment,
    BulkPriceChange,
    Customer,
    Job,
    Note,
    Notification,
    StoredObject,
    StoredObjectResourceTypes,
    Transaction,
    TranslationKey
} from '@nexdynamic/squeegee-common';
import {
    FrequencyBuilder,
    JobOccurrence,
    JobOccurrenceStatus,
    TransactionType,
    notNullUndefinedEmptyOrZero,
    to2dp
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { AttachmentService } from '../../Attachments/AttachmentService';
import { AttachmentDetailsDialog } from '../../Attachments/Dialogs/AttachmentDetailsDialog';
import { Data } from '../../Data/Data';
import { Prompt } from '../../Dialogs/Prompt';
import { InvoiceTransactionSummaryDialog } from '../../Invoices/InvoiceTransactionSummaryDialog';
import type { MessageTag } from '../../Messages';
import { MessageUtils } from '../../Messages';
import { ScheduleDetailsDialog } from '../../Schedule/Components/ScheduleDetailsDialog';
import { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { TransactionDialog } from '../../Transactions/Components/TransactionDialog';
import { TransactionUtils } from '../../Transactions/TransactionsUtils';
import { Utilities, stripHtml } from '../../Utilities';
import { CustomerDialog, CustomerDialogTab } from './CustomerDialog';

type UIActivityProps = {
    type: StoredObjectResourceTypes;
    title: string;
    content: string;
    createdDate: string;
    displayDate?: string;
    id: string;
    editable: boolean;
    icon: string;
    iconClass: string;
    view?: () => void;
    tags?: Array<MessageTag>;
}
export class UIActivity {
    public readonly date: string;
    public readonly type: StoredObjectResourceTypes;
    public readonly title: string;
    public readonly content: string;
    public readonly createdDate: string;
    public readonly id: string;
    public readonly editable: boolean;
    public readonly icon: string;
    public readonly iconClass: string = '';
    public readonly view?: () => void;
    public readonly tags?: Array<MessageTag>;

    private constructor(props: UIActivityProps) {
        this.type = props.type;
        this.title = props.title;
        this.content = props.content;
        this.createdDate = props.createdDate;
        this.id = props.id;
        this.editable = props.editable;
        this.icon = props.icon;
        this.iconClass = props.iconClass;
        this.view = props.view;
        this.tags = props.tags;

        const createdDateMoment = moment(props.displayDate || this.createdDate);
        this.date =
            createdDateMoment.hour() === 0 && createdDateMoment.minute() === 0 && createdDateMoment.second() === 0
                ? createdDateMoment.format('l')
                : createdDateMoment.format('l h:mma');
    }
    public static generateCustomerActivies(customerId: string): Array<UIActivity> {
        const activities = new Array<UIActivity>();

        const customer = Data.get<Customer>(customerId);
        if (!customer) return [];

        activities.push(UIActivity.fromCustomer(customer));

        const jobActivities = Object.values(customer.jobs).map(job => UIActivity.fromJob(job));
        activities.push(...jobActivities);

        const notificationMap = Data.all<Notification>('notifications', { customerId })
            .concat(Data.all<Notification>('notifications', { addressee: customerId }))
            .reduce<Record<string, Notification>>((x, y) => {
                x[y._id] = y;
                return x;
            }, {});

        activities.push(...Object.values(notificationMap).map(notification => UIActivity.fromNotification(notification, customer)));

        const jobOccurrences = Data.all<JobOccurrence>('joboccurrences', o => o.status !== JobOccurrenceStatus.NotDone, {
            customerId,
        });
        activities.push(...jobOccurrences.map(UIActivity.fromJobOccurrence));

        const transactions = Data.all<Transaction>('transactions', { customerId });
        activities.push(...transactions.map(UIActivity.fromTransaction));

        const notes = Data.all<Note>('notes', { relatedId: customerId });
        activities.push(...notes.map(UIActivity.fromNote));

        const attachments = AttachmentService.fetchAll(customerId);
        activities.push(...attachments.map(UIActivity.fromAttachment));

        const priceUpdates = Data.all<BulkPriceChange>('bulkpricechange');

        activities.push(...priceUpdates.map(update => UIActivity.fromPriceUpdate(customer, update)).filter(notNullUndefinedEmptyOrZero).reduce((x, y) => x.concat(y), []));
        return activities;
    }
    public static fromAttachment(attachment: Attachment) {
        const content = `${attachment.name} attached to the customer.`;
        return UIActivity.fromStoredObject('customers', 'Attachment', content, attachment, 'file', undefined, false, () =>
            new AttachmentDetailsDialog(attachment._id).show()
        );
    }

    public static fromJobOccurrence(jobOccurrence: JobOccurrence) {
        let status = '';
        let icon = 'calendar_today';
        let iconClass: string | undefined;
        let content = '';
        switch (jobOccurrence.status) {
            case JobOccurrenceStatus.NotDone:
                status = ApplicationState.localise('general.status-not-done');
                break;
            case JobOccurrenceStatus.Done:
                status = ApplicationState.localise('general.status-done');
                icon = 'event_available';
                iconClass = 'text-success';
                break;
            case JobOccurrenceStatus.Skipped:
                status = ApplicationState.localise('general.status-skipped');
                icon = 'event_busy';
                iconClass = 'text-warning';
                content += jobOccurrence.skipReason ? `${jobOccurrence.skipReason}\n` : '';
                break;
        }

        let services = Utilities.localiseList(jobOccurrence.services.map(s => s.description as TranslationKey));
        if (services) services += '\n';
        content += `${services}${ApplicationState.currencySymbol()}${Math.abs(Number(jobOccurrence.price) || 0).toFixed(2)}`;
        const customer = (jobOccurrence.customerId && Data.get<Customer>(jobOccurrence.customerId)) || undefined;
        const job = customer?.jobs[jobOccurrence.jobId];
        const view =
            customer && job ? () => void new ScheduleDetailsDialog(new ScheduleItem(job, customer, jobOccurrence)).show() : undefined;
        return UIActivity.fromStoredObject('joboccurrences', `Job ${status}`, content, jobOccurrence, icon, iconClass, undefined, view);
    }

    public static fromNotification(message: Notification, customer: Customer): UIActivity {
        let description: string;
        if (typeof message.message === 'string' && (message.type === 'SMS' || message.type === 'SMSReply')) {
            description = message.message;
        } else if (message.type === 'Email') {
            const content = MessageUtils.getContentFromNotification(message) || '';
            const body = content.match(/<body.*?>([\s\S]*)<\/body>/i)?.[1] || content;
            description = stripHtml(body).trim().replace(/</g, '&lt;').replace(/>/g, '&gt;');
            if (description.length > 300) description = description.slice(0, 300) + '...';
        } else {
            description = message.description;
        }

        const view = () => new CustomerDialog(customer, CustomerDialogTab.MESSAGES).show();

        const tags = MessageUtils.getDeliveryTagsFromNotification(message, undefined, true);

        return UIActivity.fromStoredObject(
            'notifications',
            message.type === 'SMSReply' ? 'Reply' : 'Message',
            description,
            message,
            'message',
            undefined,
            undefined,
            view,
            tags
        );
    }

    public static fromTransaction(transaction: Transaction): UIActivity {
        const action = TransactionUtils.getSubTypeTitle(transaction.transactionType, transaction.transactionSubType);
        const amount = Math.abs(Number(transaction.amount) || 0).toFixed(2);
        const formattedAmount = `${ApplicationState.currencySymbol()}${amount}`;
        const description = transaction.description ? transaction.description + '\n' : '';
        const content = `${description}${formattedAmount}`;
        let icon = 'account_balance';
        let iconClass: string | undefined;
        let tags: Array<MessageTag> | undefined;
        switch (transaction.transactionType) {
            case TransactionType.Invoice:
                icon = 'description';
                if (transaction.invoice?.paid) {
                    tags = [{ icon: '', text: 'paid', color: '#AC47BC' }];
                }
                break;
            case TransactionType.Payment:
                if (transaction.transactionSubType?.startsWith('refund')) {
                    icon = 'undo';
                    iconClass = 'text-danger';
                } else {
                    icon = 'payments';
                }
                break;
        }

        const view = () =>
            void (transaction.transactionType === TransactionType.Invoice
                ? new InvoiceTransactionSummaryDialog(transaction).show()
                : new TransactionDialog(transaction).show());

        return UIActivity.fromStoredObject('transactions', action, content, transaction, icon, iconClass, undefined, view, tags);
    }

    public static fromNote(note: Note): UIActivity {
        const content = note.content;
        return UIActivity.fromStoredObject('notes', 'Note', content, note, 'text_snippet', undefined, true);
    }

    public static fromCustomer(customer: Customer): UIActivity {
        const content = `Customer added or imported to Squeegee${customer.notes?.trim() ? `\n${customer.notes}` : ''}`;
        return UIActivity.fromStoredObject('customers', 'Customer Created', content, customer, 'person');
    }

    public static fromJob(job: Job, updateTitle?: string, updateDate?: string, view?: () => any): UIActivity {
        let frequencyText = '';
        if (job.frequencyInterval !== undefined && job.frequencyType !== undefined) {
            const frequency = FrequencyBuilder.getFrequency(
                job.frequencyInterval,
                job.frequencyType,
                job.date || moment(),
                job.frequencyDayOfMonth,
                job.frequencyWeekOfMonth,
                Array.isArray(job.frequencyDayOfWeek) ? job.frequencyDayOfWeek[0] : job.frequencyDayOfWeek
            );
            frequencyText = ApplicationState.localise(frequency.localisationKeyAndParams.key, frequency.localisationKeyAndParams.params);
        }



        const serviceList = Utilities.localiseList(job.services.map(s => s.description as TranslationKey));
        const content = `${frequencyText}${job.description?.trim() ? `\n${job.description}` : ''}\n${serviceList}`;
        return UIActivity.fromStoredObject('jobs', updateTitle || 'Job Created', content, job, 'work', undefined, undefined, view, undefined, updateDate);
    }

    public static fromPriceUpdate(customer: Customer, priceUpdate: BulkPriceChange): Array<UIActivity> | undefined {
        const changes = priceUpdate.result?.success && priceUpdate.result.changes;
        if (!changes) return;
        const jobs = Object.values(customer.jobs || {}).concat(Object.values(customer.deletedJobs || {}));



        const updates = jobs.map(job => {
            const change = changes.find(x => x.jobId === job._id);
            if (!change) return;
            return { job, change }
        }).filter(notNullUndefinedEmptyOrZero);
        if (!updates.length) return;

        const applied = priceUpdate.result?.appliedTimestamp ? moment(priceUpdate.result.appliedTimestamp, 'x').toISOString() : priceUpdate.updatedDate;

        const url = `/pricing/changes/${priceUpdate._id}`

        const view = !ApplicationState.isInAnyRole(['Owner', 'Admin'])
            ? undefined
            : () => ApplicationState.navigateToRouteFragment(url)

        const isPercent = priceUpdate.type.is === 'percentage';
        const value = to2dp(priceUpdate.type.value * (isPercent ? 100 : 1));
        const text = `${!isPercent ? ApplicationState.currencySymbol() : ''}${value.toFixed(2)}${isPercent ? '%' : ''}`

        return updates.map(update => {

            const title = `Price Updated from ${ApplicationState.currencySymbol()}${update.change.oldPrice.toFixed(2)} to ${ApplicationState.currencySymbol()}${update.change.newPrice.toFixed(2)} (${text})`
            const activity = UIActivity.fromJob(update.job, title, applied, view);
            return activity;

        });
    }

    static fromStoredObject(
        type: StoredObjectResourceTypes,
        title: string,
        content: string,
        storedObject: StoredObject,
        icon: string,
        iconClass = '',
        editable = false,
        view?: () => void,
        tags?: Array<MessageTag>,
        updateDate?: string,
    ): UIActivity {
        let displayDate: string | undefined;
        switch (storedObject.resourceType) {
            case 'joboccurrences':
                displayDate = JobOccurrence.getDate(storedObject as JobOccurrence);
                break;
            case 'transactions':
                displayDate = (storedObject as Transaction).date;
                break;
            case 'notifications': {
                (storedObject as Notification).sendAtSecondsTimestamp ? moment((storedObject as Notification).sendAtSecondsTimestamp, 'x').toISOString() : undefined;
                break;
            }

        }

        return new UIActivity({
            type,
            title,
            content,
            createdDate: displayDate || updateDate || storedObject.createdDate,
            displayDate,
            id: storedObject._id,
            editable,
            icon,
            iconClass,
            view,
            tags,
        }
        );
    }



    public static async delete(activity: UIActivity) {
        if (!activity.editable) return false;

        const note = Data.get(activity.id);
        if (note?.resourceType !== 'notes') return false;

        if (!(await Prompt.continue(`Are you sure you want to delete this note?` as TranslationKey))) return false;

        Data.delete(note);
        return true;
    }
}
