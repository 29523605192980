import {
    ConnectedServiceConfigData,
    type ConnectedServiceSettings,
    type Customer,
    type PaymentAccountProviders,
    type StoredObjectResourceTypes,
    type TranslationKey,
} from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { ApplicationState } from '../ApplicationState';
import { AccountsLedgerService } from '../ChartOfAccounts/AccountsLedgerService';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';
import { Data } from '../Data/Data';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { Select } from '../Dialogs/Select';
import { TextDialog } from '../Dialogs/TextDialog';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { openSystemBrowser } from '../Utilities';
import { ConnectedServicesService } from './ConnectedServicesService';
import { CustomerManualSyncService } from './Customers/CustomerManualSyncService';
import type { IConnectedServiceInfo } from './IConnectedServiceInfo';
import { TransactionsManualSyncService } from './Payments/TransactionsManualSyncService';

export class ConnectedServiceConfig extends CustomDialog<void> {
    protected isConnected: boolean = Api.isConnected;
    // protected syncOutData: ConnectedServiceSyncTypes | undefined = {};
    protected settings: ConnectedServiceSettings = {};

    protected hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;
    protected enableAutoStripePayInt: boolean = ApplicationState.getSetting<boolean>('global.stripe.payments.set-auto-on-signup', false);
    protected enableAutoGoCardlessPayInt: boolean = ApplicationState.getSetting<boolean>(
        'global.gocardless.payments.set-auto-on-signup',
        false
    );

    protected get enableAutoGoCardlessPay() {
        return this.enableAutoGoCardlessPayInt;
    }

    protected set enableAutoGoCardlessPay(value: boolean) {
        this.enableAutoGoCardlessPayInt = value;
        ApplicationState.setSetting('global.gocardless.payments.set-auto-on-signup', value);
    }

    protected get enableAutoStripePay() {
        return this.enableAutoStripePayInt;
    }

    protected set enableAutoStripePay(value: boolean) {
        this.enableAutoStripePayInt = value;
        ApplicationState.setSetting('global.stripe.payments.set-auto-on-signup', value);
    }

    private _dataChangedSub: Subscription;

    constructor(protected provider: IConnectedServiceInfo) {
        super('connectedServiceConfigDialog', '../ConnectedServices/ConnectedServiceConfig.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'payment-providers-dialog',
            isSecondaryView: true,
        });
        this._dataChangedSub = DataRefreshedEvent.subscribe(async (event: DataRefreshedEvent) => {
            if (!event.getUpdatedSetting('global.oauth-connections')) return;

            const response = await Api.get<Array<IConnectedServiceInfo>>(Api.apiEndpoint, '/api/oauth');
            const providers = response?.data;
            if (!providers) return;
            const prov = providers.find(x => x.id === this.provider.id);
            if (!prov) return;
            this.provider = prov;
            this.init();
        });
        this.isConnected = Api.isConnected;
    }

    async init() {
        this.isConnected = Api.isConnected;
        this.syncCustomers = this.isSynced('customers');
        this.syncInvoices = this.isSynced('transactions');
        this.syncPayments = this.isSynced('payments');
        if (this.provider.id === 'gocardless' && this.provider.status !== 'not-connected') {
            await this.getGoCardlessStatus();
            const gc = await AccountsLedgerService.getGoCardlessPaymentAccount();
            this.goCardlessPaymentAccountEnabled = !!gc;
        }
    }

    async disconnect() {
        await ConnectedServicesService.disconnect(this.provider.id);
        this.cancel();
    }
    detached() {
        this._dataChangedSub && this._dataChangedSub.dispose();
    }

    //Stripe
    protected async updateDefaultCards() {
        new LoaderEvent(true, true, 'loader.updating-default-cards-customers');
        try {
            for (const c of Data.all<Customer>('customers')) {
                const stripeCustomerId = c.externalIds?.stripe;
                if (!stripeCustomerId) continue;

                await Api.setCustomerCardOnFile(c._id, stripeCustomerId);
            }
        } catch (error) {
            Logger.error('Failed to updated cards on file for all customers.', error);
        }
        new LoaderEvent(false);
    }

    protected async refreshSettings() {
        try {
            await Api.post(Api.apiEndpoint, `/api/oauth/${this.provider.id}/refresh-settings`);
        } catch (error) {
            Logger.error('Failed to updated cards on file for all customers.', error);
        }
    }

    public import() {
        ConnectedServicesService.importCustomers(this.provider.id);
    }

    public connect() {
        if (!this.provider.connectUrl) return;
        openSystemBrowser(this.provider.connectUrl);
    }

    // Legacy checks for gocardlessPayment

    protected goCardlessVerifyLink: string;

    protected goCardlessStatus: string;

    public async getGoCardlessStatus() {
        try {
            const response: any = await Api.get(Api.apiEndpoint, '/api/payments/gocardless-verify');
            if (response && response.data && response.data.verification_status) {
                Logger.info('GoCardless Account status' + response.verification_status);
                this.goCardlessStatus = response.data.verification_status?.replace(/_/g, ' ');
                if (this.goCardlessStatus === 'action_required') {
                    new NotifyUserMessage('notifications.gocardless-account-verification-required');
                }
            }
        } catch (error) {
            Logger.error(`Error during getGoCardlessStatus in PaymentProvidersDialog`, error);
        }
    }

    private addSyncResources(dataType: string) {
        if (!this.settings) this.settings = {};
        if (!this.settings[this.provider.id]) (this.settings as any)[this.provider.id] = { syncOut: [], lookups: {} };
        const syncResources = this.settings[this.provider.id]?.syncOut as string[];
        if (syncResources && syncResources.indexOf(dataType) === -1) {
            syncResources.push(dataType);
            ApplicationState.setSetting('global.connected-services', this.settings);
        }
    }

    private removeSyncResource(dataType: string) {
        if (!this.settings) return;
        if (!this.settings[this.provider.id]) return;
        const syncResources = this.settings[this.provider.id]?.syncOut as string[];
        if (!syncResources) return;
        if (syncResources.indexOf(dataType) !== -1) {
            syncResources.splice(syncResources.indexOf(dataType));
            ApplicationState.setSetting('global.connected-services', this.settings);
        }
    }

    private isSynced(dataType: StoredObjectResourceTypes | string) {
        this.settings = ApplicationState.getSetting<ConnectedServiceSettings>('global.connected-services') || {};
        if (!this.settings) return false;
        const syncResources = this.settings[this.provider.id]?.syncOut as string[];
        return (syncResources && syncResources.indexOf(dataType) !== -1) || false;
    }

    syncCustomers: boolean = this.isSynced('customers');
    syncInvoices: boolean = this.isSynced('invoices');
    syncPayments: boolean = this.isSynced('payments');

    togglePayments() {
        if (!this.syncPayments) this.removeSyncResource('payments');
        else this.addSyncResources('payments');
    }
    toggleInvoices() {
        if (!this.syncInvoices) this.removeSyncResource('invoices');
        else this.addSyncResources('invoices');
    }
    toggleCustomers() {
        if (!this.syncCustomers) this.removeSyncResource('customers');
        else this.addSyncResources('customers');
    }

    updateAutoStripePayment() {
        ApplicationState.setSetting('global.stripe.payments.set-auto-on-signup', this.enableAutoStripePay);
    }

    updateAutoGoCardlessPayment() {
        ApplicationState.setSetting('global.gocardless.payments.set-auto-on-signup', this.enableAutoGoCardlessPay);
    }

    async lookup(setting: string, title: string) {
        if (!this.provider.id) return;
        if (!this.settings) return;
        const providerSettings = this.settings[this.provider.id];
        if (!providerSettings) return;
        const lookupValues = providerSettings.lookups[setting];
        if (!lookupValues) return;
        const select = new Select(title as TranslationKey, lookupValues, 'text', 'value', (providerSettings as any)[setting]);
        const result = await select.show();
        if (select.cancelled) return;
        (this.settings[this.provider.id] as any)[setting] = { value: result.value, text: result.text };
        await ApplicationState.setSetting('global.connected-services', this.settings);
    }

    async datePicker(setting: string, title: string) {
        if (!this.provider.id) return;
        if (!this.settings) return;
        const providerSettings = this.settings[this.provider.id];
        if (!providerSettings) return;

        const datePicker = new DateTimePicker(false, (providerSettings as any)[setting], title as TranslationKey, true);
        datePicker.okOnSelect = true;
        datePicker.cancelLabel = 'general.finished';
        datePicker.init();
        await datePicker.open();
        if (datePicker.canceled) return;

        (this.settings[this.provider.id] as any)[setting] = datePicker.selectedDate;
        await ApplicationState.setSetting('global.connected-services', this.settings);
    }

    async editText(setting: string, title: string) {
        if (!this.provider.id) return;
        if (!this.settings) return;

        if (!this.settings[this.provider.id]) this.settings[this.provider.id] = new ConnectedServiceConfigData();
        const providerSettings = this.settings[this.provider.id];
        if (!providerSettings) return;

        const txtDlg = new TextDialog(title as TranslationKey, title as TranslationKey, (providerSettings as any)[setting], '');
        const newValue = await txtDlg.show();
        if (txtDlg.cancelled) return;

        (this.settings[this.provider.id] as any)[setting] = newValue;
        await ApplicationState.setSetting('global.connected-services', this.settings);
    }

    public importPayments() {
        ConnectedServicesService.importPayments(this.provider.id);
    }

    public importPaymentAccounts() {
        ConnectedServicesService.importPaymentAccounts(this.provider.id as PaymentAccountProviders);
    }

    public async manualSyncPayments() {
        await TransactionsManualSyncService.syncUnsyncedPayments(this.provider.id, this.settings[this.provider.id]?.dontSyncBeforeDate);
    }

    public async manualSyncInvoices() {
        await TransactionsManualSyncService.syncUnsyncedInvoices(this.provider.id, this.settings[this.provider.id]?.dontSyncBeforeDate);
    }

    public async manualSyncCustomers() {
        await CustomerManualSyncService.syncCustomers(this.provider.id);
    }

    public async enableGoCardlessAccount() {
        await AccountsLedgerService.enableGoCardlessPaymentAccount();
        this.goCardlessPaymentAccountEnabled = true;
    }


    protected goCardlessPaymentAccountEnabled: boolean;

}
