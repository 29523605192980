import type { AccountUser, Customer, IFranchiseeCustomerSummary, Team, Transaction } from '@nexdynamic/squeegee-common';
import {
    FrequencyType,
    Job,
    SyncMode,
    TransactionType,
    notNullUndefinedEmptyOrZero,
    searchCustomers,
    searchInvoices,
    wait,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { EditProfileDialog } from '../Account/EditProfileDialog';
import { ApplicationState } from '../ApplicationState';
import { CustomerCreditDialog } from '../Customers/Components/CustomerCreditDialog';
import { CustomerDialog } from '../Customers/Components/CustomerDialog';
import { CustomerFormDialog } from '../Customers/Components/CustomerFormDialog';
import { SelectCustomerDialog } from '../Customers/Components/SelectCustomerDialog';
import { Data } from '../Data/Data';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { LauncherCommandListDialog } from '../Dialogs/LauncherCommandListDialog';
import { LauncherKeybinds } from '../Dialogs/LauncherKeybinds';
import { ExpenseFormDialog } from '../Expenses/Components/ExpenseFormDialog';
import { GlobalFlags } from '../GlobalFlags';
import type { IRoute } from '../IRoute';
import { ReportsService } from '../Insights/ReportsService';
import { CreateOrEditInvoiceTransactionDialog } from '../Invoices/CreateOrEditInvoiceTransactionDialog';
import { InvoiceTransactionSummaryDialog } from '../Invoices/InvoiceTransactionSummaryDialog';
import { NewJobDialog } from '../Jobs/Components/NewJobDialog';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { RoundDataDialog } from '../Rounds/RoundDataDialog';
import { RoundService } from '../Rounds/RoundService';
import { RouteUtil } from '../Routes';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { ServiceDataDialog } from '../Services/ServiceDataDialog';
import { ServicesService } from '../Services/ServicesService';
import { SettingsDialog } from '../Settings/SettingsDialog';
import { UserService } from '../Users/UserService';
import { Utilities, animate } from '../Utilities';
import { isDevMode } from '../isDevMode';
import './Launcher.scss';
import { LauncherEvent } from './LauncherEvent';

type CommandEntry<EntryType> = { title: string; subtitle: string; value: EntryType; open: () => any };
export class Launcher extends CustomDialog<void> {
    private static _instance?: Launcher;
    protected loaderWidth = 0;
    private _loader?: NodeJS.Timeout;
    public static toggle() {
        if (!ApplicationState.hasAdvancedOrAbove) return;

        if (Launcher._instance) {
            Launcher._instance.cancel();
            delete Launcher._instance;
        } else {
            Launcher._instance = new Launcher();
            Launcher._instance.show(DialogAnimation.GROW);
        }
        new LauncherEvent(!!Launcher._instance);
    }
    onCancel(): Promise<boolean> {
        delete Launcher._instance;
        new LauncherEvent(false);
        return Promise.resolve(true);
    }

    protected input: HTMLInputElement;
    private customers = Data.all<Customer>('customers').slice();
    private invoiceTransactions = Data.all<Transaction>('transactions', {}).slice();
    protected options: Array<CommandEntry<any>> = [];
    protected label = ApplicationState.localise('launcher.label');
    protected routes = RouteUtil.getRoutes().filter(
        x =>
            (x.settings?.showInNav || x.name === 'alerts') &&
            x.settings?.enabled?.() !== false &&
            (!x.settings.roles || ApplicationState.isInAnyRole(x.settings.roles))
    );
    protected roundsData = RoundService.getRoundsData();
    protected servicesData = ServicesService.getServicesData();

    protected reportsData = ReportsService.getExecutableReports();

    protected getOptions = async (searchText: string, commandEntryValue: any): Promise<Array<CommandEntry<any>>> => {
        // If selecting, open this item.
        if (commandEntryValue) {
            this.ok();
            new LauncherEvent(false);
            this.options.find(x => x.value === commandEntryValue)?.open();
            Launcher.toggle();
            return [];
        }

        // Build the new matching options.
        const options: Array<CommandEntry<any>> = [];

        // Don't search until there is search text.
        if (!searchText?.trim()) return [];

        searchText = searchText.toLowerCase();

        this.startLoading();
        const requireLoading = new Array<Promise<any>>();

        // Initiate cross franchise search.
        requireLoading.push(this.addFranchiseCustomers(searchText, options).catch());

        // Get first 10 matching rounds.
        requireLoading.push(this.addRounds(searchText, options).catch());

        // Get first 10 matching services.
        requireLoading.push(this.addServices(searchText, options).catch());

        // Add the reports.
        requireLoading.push(this.addReports(searchText, options).catch());

        // Get support
        options.unshift(...this.getSupport(searchText));

        // Add the setting screen.
        options.push(...this.getSettings(searchText));

        // Add the setting screen.
        options.push(...this.getDateNavigator(searchText));

        // Add the about screen.
        options.push(...this.getAboutScreen(searchText));

        // Add the sync.
        options.push(...this.getSync(searchText));

        // Add the profile screen.
        options.push(...this.getProfile(searchText));

        // Add the quickstart screen.
        options.push(...this.getQuickstart(searchText));

        // Get first 10 matching customers.
        options.push(...this.getCustomers(searchText));

        // Get first 10 matching invoices.
        options.push(...this.getInvoices(searchText));

        // Get matching routes.
        options.push(...this.getRoutes(searchText));

        // Get create dialogs.
        options.push(...this.getCreateDialogs(searchText));

        // Send alerts to x
        options.push(...this.getSendAlerts(searchText));

        // List of commands.
        options.push(...this.getCommands(searchText));

        // List of keybindings.
        options.push(...this.getKeybindings(searchText));

        this.addNotchDebug(options, searchText);

        // Stop the loader after all the async data is loaded.
        Promise.all(requireLoading).then(() => {
            wait(350).then(() => {
                if (options.length === 0) options.push(this.getHelpWith(searchText));
                this.stopLoading();
            });
        });

        this.options = options;
        return this.options;
    };

    private addNotchDebug(options: Array<CommandEntry<any>>, searchText?: string) {
        // Check for notch debug request
        if (searchText?.toLowerCase() !== 'notch') return;

        const title = 'Toggle Notch Debug';
        const subtitle = 'Add in a mock top and bottom phone notch to emulate a phone screen.';
        const value = 'toggleNotchDebug';
        const open = () => this.toggleNotchDebug();
        options.push({ title, subtitle, value, open });
    }

    private toggleNotchDebug = () => {
        const mobileNotchDebugging = ((window as any).mobileNotchDebugging = !(window as any).mobileNotchDebugging);
        const fakeNotches = document.getElementById('mobile-notch-debug');
        if (!fakeNotches) return Logger.error('Failed to find mobile-notch-debug element');

        Logger.info('Notch debug enabled:');

        document.documentElement.style.setProperty('--notch-inset-top', mobileNotchDebugging ? '50px' : '0px');
        document.documentElement.style.setProperty('--notch-inset-bottom', mobileNotchDebugging ? '28px' : '0px');
        document.documentElement.style.setProperty(
            '--max-view-height',
            mobileNotchDebugging ? 'calc(100vh - var(--notch-inset-top))' : '100vh'
        );
        fakeNotches.style.display = mobileNotchDebugging ? 'block' : 'none';
    };

    private getHelpWith(searchText: string): CommandEntry<any> {
        return {
            title: ApplicationState.localise('launcher.no-results'),
            subtitle: ApplicationState.localise('launcher.no-results-help-with', { with: searchText }),
            value: 'help-me-with',
            open: () => ApplicationState.getSupport(searchText, true),
        };
    }

    private _isFranchiseOwnerOrAdmin?: boolean;

    protected get isFranchiseOwnerOrAdmin() {
        if (this._isFranchiseOwnerOrAdmin === undefined) {
            const isOwnerOrAdmin = ApplicationState.isInAnyRole(['Owner', 'Admin']);

            const isFranchiseAccount = ApplicationState.isFranchise() || !!ApplicationState.instance.franchiseOwnerEmail;

            this._isFranchiseOwnerOrAdmin = isOwnerOrAdmin && isFranchiseAccount;

            if (this._isFranchiseOwnerOrAdmin) return true;

            const url = `/api/franchise/is-franchise-owner-or-admin?franchiseOwnerEmail=${ApplicationState.instance.franchiseOwnerEmail}`;
            Api.get<{ isFranchiseOwnerOrAdmin: boolean }>(null, url).then(result => {
                this._isFranchiseOwnerOrAdmin = !!result?.data.isFranchiseOwnerOrAdmin;
            });
        }
        return !!this._isFranchiseOwnerOrAdmin;
    }

    private async addFranchiseCustomers(searchText: string, options: Array<CommandEntry<any>>) {
        if (!this.isFranchiseOwnerOrAdmin) return [];

        if (searchText?.length < 4) return [];

        const customerSearch = await Api.get<Array<IFranchiseeCustomerSummary>>(
            null,
            `/api/franchise/franchise-customers?q=${encodeURIComponent(searchText)}`
        );
        const franchiseeCustomers = (customerSearch && customerSearch.data) || [];

        for (const franchiseeCustomer of franchiseeCustomers.filter(c => !Data.get(c.id))) {
            options.unshift({
                title: franchiseeCustomer.name,
                subtitle: franchiseeCustomer.address,
                value: franchiseeCustomer,
                open: () => {
                    Utilities.goToRootUrl({
                        applicationRouteWithLeadingSlash: `/customers/${franchiseeCustomer.id}`,
                        queryStringParams: { dataEmail: franchiseeCustomer.franchiseeEmail },
                    });
                },
            });
        }
    }

    stopLoading() {
        const loader = this._loader;
        delete this._loader;
        clearInterval(loader);
        this.loaderWidth = 0;
    }

    private startLoading() {
        if (this._loader) return;

        this._loader = setInterval(() => {
            const next = Utilities.randomInteger(1, 5);
            if (this.loaderWidth + next > 100) this.loaderWidth = 0;
            this.loaderWidth += next;
        }, 100);
    }

    private getDateNavigator(searchText: string): Array<CommandEntry<any>> {
        if (searchText.length < 6) return [];

        let date = ApplicationState.account.language.endsWith('US') ? moment(searchText, 'MM/DD/YYYY') : moment(searchText, 'DD/MM/YYYY');
        if (!date.isValid()) date = moment(searchText);
        if (!date.isValid() || date.isBefore(moment().subtract(5, 'years')) || date.isAfter(moment().add(5, 'years'))) return [];

        return [
            {
                title: `${date.format('ll')}`,
                subtitle: `Open the work planner at ${date.format('ll')}`,
                value: date.toString(),
                open: async () =>
                    await ApplicationState.navigateTo('schedule', { date: date.format('YYYY-MM-DD') }, { replace: true, trigger: true }),
            },
        ];
    }

    private getSync(searchText: string) {
        if (searchText.length < 3) return [];

        if (!ApplicationState.localise('settings.general-full-sync-title').toLocaleLowerCase().includes(searchText)) return [];

        return [
            {
                title: ApplicationState.localise('settings.general-full-sync-title'),
                subtitle: ApplicationState.localise('settings.general-full-sync-text'),
                value: 'view-settings',
                open: () => Data.fullSync(SyncMode.Full),
            },
        ];
    }

    private getAboutScreen(searchText: string) {
        if (searchText.length < 3 || !ApplicationState.isInAnyRole(['Owner', 'Admin'])) return [];

        if (!ApplicationState.localise('route-config.about-title').toLocaleLowerCase().includes(searchText)) return [];

        return [
            {
                title: ApplicationState.localise('route-config.about-title'),
                subtitle: ApplicationState.localise('general.open-view', {
                    view: ApplicationState.localise('route-config.about-title'),
                }),
                value: 'view-about',
                open: () => ApplicationState.navigateTo('about'),
            },
        ];
    }

    private getSettings(searchText: string) {
        if (searchText.length < 3 || !ApplicationState.isInAnyRole(['Owner', 'Admin'])) return [];

        if (!ApplicationState.localise('general.settings').toLocaleLowerCase().includes(searchText)) return [];

        return [
            {
                title: ApplicationState.localise('route-config.settings-title'),
                subtitle: ApplicationState.localise('general.open-view', {
                    view: ApplicationState.localise('route-config.settings-title'),
                }),
                value: 'view-settings',
                open: () => new SettingsDialog().show(),
            },
        ];
    }

    private getSendAlerts(searchText: string): Array<CommandEntry<AccountUser | Team>> {
        if (!/^@/.test(searchText)) return [];

        const users = UserService.getActiveUsers().filter(u => u.email !== RethinkDbAuthClient.session?.email);

        const today = moment().format('YYYY-MM-DD');
        const teams = Data.all<Team>('team', t => (!t.activeFrom || t.activeFrom <= today) && (!t.activeTo || t.activeTo >= today));

        const realSearchText = searchText.slice(1);
        return [...users, ...teams]
            .filter(userOrTeam => !realSearchText || userOrTeam.name.toLocaleLowerCase().includes(realSearchText))
            .map(userOrTeam => ({
                title: `@${userOrTeam.name} (${
                    userOrTeam.resourceType === 'team' ? ApplicationState.localise('teams.team') : `${(userOrTeam as AccountUser).email}`
                })`,
                subtitle: ApplicationState.localise('alert-message.send-alert-to', { to: userOrTeam.name }),
                value: userOrTeam,
                open: () => UserService.sendAlert(userOrTeam),
            }));
    }

    private getProfile(searchText: string) {
        if (searchText.length < 3) return [];

        if (!ApplicationState.localise('general.profile').toLocaleLowerCase().includes(searchText)) return [];

        return [
            {
                title: ApplicationState.localise('general.profile'),
                subtitle: ApplicationState.localise('general.open-view', {
                    view: ApplicationState.localise('general.profile'),
                }),
                value: 'view-profile',
                open: () => new EditProfileDialog().show(),
            },
        ];
    }

    private getQuickstart(searchText: string) {
        if (
            !ApplicationState.isInAnyRole(['Owner', 'Admin']) ||
            searchText.length < 3 ||
            ApplicationState.subscription?.product?.productLevel < 2 ||
            ApplicationState.subscription?.status !== 'trial'
        )
            return [];

        if (!ApplicationState.localise('route-config.quickstart-title').toLocaleLowerCase().includes(searchText)) return [];

        return [
            {
                title: ApplicationState.localise('route-config.quickstart-title'),
                subtitle: ApplicationState.localise('general.open-view', {
                    view: ApplicationState.localise('route-config.quickstart-title'),
                }),
                value: 'view-quickstart',
                open: () => ApplicationState.navigateTo('quickstart'),
            },
        ];
    }

    private getCreateDialogs(searchText: string) {
        const options = new Array<CommandEntry<any>>();

        if (!ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator'])) return options;

        let creating = false;
        const newTexts = [
            ApplicationState.localise('general.new').toLocaleLowerCase() + ' ',
            ApplicationState.localise('general.create').toLocaleLowerCase() + ' ',
            ApplicationState.localise('general.add').toLocaleLowerCase() + ' ',
        ];

        for (const text of newTexts) {
            if (searchText.startsWith(text)) {
                searchText = searchText.slice(text.length + 1);
                creating = true;
                break;
            }
        }

        if (!creating) return options;

        if (ApplicationState.localise('general.customer').includes(searchText)) {
            options.push({
                title: ApplicationState.localise('actions.add-new-customer'),
                subtitle: '',
                value: 'new-customer',
                open: () => new CustomerFormDialog().show(),
            });
        }

        if (ApplicationState.localise('general.job').includes(searchText)) {
            options.push({
                title: ApplicationState.localise('actions.add-job'),
                subtitle: '',
                value: 'new-job',
                open: () => this.addJobForCustomer(),
            });
        }

        if (ApplicationState.isInAnyRole(['Owner', 'Admin'])) {
            if (ApplicationState.localise('general.expense').includes(searchText)) {
                options.push({
                    title: ApplicationState.localise('actions.add-expense'),
                    subtitle: '',
                    value: 'new-expense',
                    open: () => new ExpenseFormDialog().show(),
                });
            }
        }

        if (ApplicationState.localise('general.invoice').includes(searchText)) {
            options.push({
                title: ApplicationState.localise('actions.create-invoice'),
                subtitle: '',
                value: 'new-invoice',
                open: () => this.addInvoiceForCustomer(),
            });
        }

        if (ApplicationState.localise('general.payment').includes(searchText)) {
            options.push({
                title: ApplicationState.localise('actions.create-invoice'),
                subtitle: '',
                value: 'new-invoice',
                open: () => this.addPaymentForCustomer(),
            });
        }

        return options;
    }
    public addInvoiceForCustomer = async () => {
        let customer: Customer | undefined;
        try {
            const pickCustomerDialog = new SelectCustomerDialog(undefined);
            customer = await pickCustomerDialog.show(DialogAnimation.SLIDE_UP);
            if (pickCustomerDialog.cancelled || !customer) return;

            CreateOrEditInvoiceTransactionDialog.createDialog(customer)?.show();
        } catch (error) {
            Logger.error('failed to get customer for invoice creation', error);
        }
    };

    public addPaymentForCustomer = async () => {
        let customer: Customer | undefined;
        try {
            const pickCustomerDialog = new SelectCustomerDialog(undefined);
            customer = await pickCustomerDialog.show(DialogAnimation.SLIDE_UP);
            if (pickCustomerDialog.cancelled || !customer) return;

            CustomerCreditDialog.createForCustomer(customer).show();
        } catch (error) {
            Logger.error('failed to get customer for payment creation.', error);
        }
    };
    public addJobForCustomer = async () => {
        let jobDate: string | undefined;
        let customer: Customer | undefined;
        try {
            const pickCustomerDialog = new SelectCustomerDialog(undefined);
            customer = await pickCustomerDialog.show(DialogAnimation.SLIDE_UP);
            if (pickCustomerDialog.cancelled || !customer) return;

            const frequency = ApplicationState.defaultJobFrequency;
            const type = (frequency && frequency.type) || FrequencyType.NoneRecurring;
            const interval = (frequency && frequency.interval) || 0;

            const newJob = new Job(
                customer._id,
                undefined,
                undefined,
                jobDate,
                Utilities.copyObject(customer.address),
                undefined,
                undefined,
                undefined,
                undefined,
                interval,
                type,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                ApplicationState.account.defaultJobDuration
            );
            const dialog = new NewJobDialog(customer, newJob);

            if (pickCustomerDialog.customerIsNew) {
                const customerFormDialog = new CustomerFormDialog(customer);
                const originalCustomer = customer;
                customerFormDialog.beforeNextDialog = async () => {
                    dialog.job.location = Utilities.copyObject(originalCustomer.address);
                };

                customerFormDialog.nextDialog = dialog;
                customer = await customerFormDialog.show();
            } else {
                await dialog.show();
            }
        } catch (error) {
            Logger.error('Error in addJobForCustomer() on ScheduleView', { jobDate, customer, error });
        }
    };

    private async addServices(searchText: string, options: Array<CommandEntry<any>>, howMany = 10) {
        if (!ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator'])) return [];

        options.push(
            ...(await this.servicesData)
                .filter(r => r.tag.description?.toLocaleLowerCase().includes(searchText))
                .slice(0, howMany)
                .map(serviceData => ({
                    title: `${serviceData.tag.description} (${serviceData.jobs.length} ${ApplicationState.localise('general.jobs')})`,
                    subtitle: ApplicationState.localise('rounds.view-service', {
                        service: serviceData.tag.description || 'Un-named Service',
                    }),
                    value: serviceData,
                    open: () => new ServiceDataDialog(serviceData.tag._id).show(),
                }))
        );
    }

    private async addReports(searchText: string, options: Array<CommandEntry<any>>, howMany = 10) {
        options.push(
            ...(await this.reportsData)
                .filter(r => r.name.toLocaleLowerCase().includes(searchText))
                .slice(0, howMany)
                .map(executableReport => ({
                    title: ApplicationState.localise('launcher.report-type', {
                        type: ApplicationState.localise(executableReport.typeKey),
                    }),
                    subtitle: ApplicationState.localise(executableReport.name),
                    value: executableReport,
                    open: () => executableReport.show(),
                }))
        );
    }

    private async addRounds(searchText: string, options: Array<CommandEntry<any>>, howMany = 10) {
        if (!ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator'])) return [];

        options.push(
            ...(await this.roundsData)
                .filter(r => r.tag.description?.toLocaleLowerCase().includes(searchText))
                .slice(0, howMany)
                .map(roundData => ({
                    title: `${roundData.tag.description} (${roundData.jobs.length} ${ApplicationState.localise('general.jobs')})`,
                    subtitle: ApplicationState.localise('rounds.view-round', { round: roundData.tag.description || 'Un-named Round' }),
                    value: roundData,
                    open: () => new RoundDataDialog(roundData, false).show(),
                }))
        );
    }

    private getCommands(searchText: string) {
        if (searchText.length < 3) return [];

        const isCommandListRequest =
            ApplicationState.localise('launcher.list-title').toLocaleLowerCase().includes(searchText) ||
            ApplicationState.localise('general.need-help-commands').toLocaleLowerCase().includes(searchText) ||
            ApplicationState.localise('general.commands').toLocaleLowerCase().includes(searchText);
        if (!isCommandListRequest) return [];

        return [
            {
                title: ApplicationState.localise('launcher.list-title'),
                subtitle: ApplicationState.localise('general.open-view', {
                    view: ApplicationState.localise('launcher.list-title'),
                }),
                value: 'view-commandList',
                open: () => new LauncherCommandListDialog().show(),
            },
        ];
    }

    private getSupport(searchText: string) {
        if (searchText.length < 3) return [];
        const supportRequest =
            ApplicationState.localise('general.help').toLocaleLowerCase().includes(searchText) ||
            ApplicationState.localise('general.support').toLocaleLowerCase().includes(searchText) ||
            ApplicationState.localise('general.assistance').toLocaleLowerCase().includes(searchText) ||
            ApplicationState.localise('route-config.training-title').toLocaleLowerCase().includes(searchText);

        if (!supportRequest) return [];

        return [
            {
                title: ApplicationState.localise('route-config.training-title'),
                subtitle: ApplicationState.localise('general.open-view', {
                    view: ApplicationState.localise('route-config.training-title'),
                }),
                value: 'view-training',
                open: () => ApplicationState.navigateTo('training'),
            },
            {
                title: ApplicationState.localise('general.support'),
                subtitle: ApplicationState.localise('general.open-view', {
                    view: ApplicationState.localise('general.support'),
                }),
                value: 'view-support',
                open: () => ApplicationState.getSupport('Squeegee App Support', true),
            },
        ];
    }

    private getKeybindings(searchText: string) {
        if (searchText.length < 3) return [];

        const isKeybindRequest =
            ApplicationState.localise('general.keybinds').toLocaleLowerCase().includes(searchText) ||
            ApplicationState.localise('general.keys').toLocaleLowerCase().includes(searchText);
        if (!isKeybindRequest) return [];

        return [
            {
                title: ApplicationState.localise('launcher.keybinds-title'),
                subtitle: ApplicationState.localise('general.open-view', {
                    view: ApplicationState.localise('launcher.keybinds-title'),
                }),
                value: 'view-commandList',
                open: () => new LauncherKeybinds('Keybinds', false).show(),
            },
        ];
    }
    private getInvoices(searchText: string, howMany = 10) {
        if (!ApplicationState.isInAnyRole(['Owner', 'Admin'])) return [];

        if (searchText.length < 3 && !/[0-9]{1,2}?/.test(searchText)) return [];
        const invoiceText = ApplicationState.localise('general.invoice').toLocaleLowerCase() + ' ';
        if (searchText.startsWith(invoiceText)) searchText = searchText.replace(invoiceText, '');
        let invoices: Array<Transaction>;
        if (searchText.startsWith('#')) {
            searchText = searchText.replace('#', '');
            invoices = Data.all<Transaction>('transactions', x => x.invoice?.invoiceNumber === Number(searchText), {
                transactionType: TransactionType.Invoice,
            }).slice();
        } else {
            invoices = searchInvoices(this.invoiceTransactions, searchText);
        }

        return invoices.slice(0, howMany).map(invoiceTransaction => ({
            title: ApplicationState.localise('general.invoice') + ` #${invoiceTransaction.invoice?.invoiceNumber || ''}`,
            subtitle: invoiceTransaction.invoice?.billTo || '',
            value: invoiceTransaction,
            open: () => new InvoiceTransactionSummaryDialog(invoiceTransaction).show(),
        }));
    }

    private getCustomers(searchText: string, howMany = 10) {
        if (!ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator'])) return [];

        let edit = false;
        if (searchText.startsWith('edit ' || 'general.modify ' || 'general.update ')) {
            edit = true;
            searchText = searchText.substring(5);
        }

        if (searchText.length < 3) return [];

        const mobilePrefixes = ApplicationState.account.smsMobilePhonePrefixes?.split(',').map(x => x.trim());
        const localCustomers = searchCustomers({ customers: this.customers, searchText, searchJobs: true, mobilePrefixes })
            .slice(0, howMany)
            .map(customer => ({
                title: this.isFranchiseOwnerOrAdmin ? `${customer.name}  (${ApplicationState.account.businessName})` : customer.name,
                subtitle: customer.address?.addressDescription || '',
                value: customer,
                open: () => (edit ? new CustomerFormDialog(customer) : new CustomerDialog(customer)).show(),
            }));

        return localCustomers;
    }

    private getRoutes(searchText: string) {
        return this.routes
            .map(routeConfig => {
                if (!routeConfig.title) return;

                const routeTitle = ApplicationState.localise(routeConfig.title);
                const route = (typeof routeConfig.route === 'string' && routeConfig.route.split('/:')[0]) || undefined;
                if (!route || !routeTitle?.toLocaleLowerCase().includes(searchText)) return;
                const commandEntry: CommandEntry<IRoute> = {
                    title: routeTitle,
                    subtitle: ApplicationState.localise('general.open-view', { view: routeTitle }),
                    value: routeConfig,
                    open: () => ApplicationState.navigateTo(route),
                };
                return commandEntry;
            })
            .filter(notNullUndefinedEmptyOrZero);
    }

    protected selected: CommandEntry<any>;
    constructor() {
        super('launcher', '../Launcher/Launcher.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'launcher',
            allowClickOff: true,
        });
    }

    public async init() {
        await animate();
        const input = this.input?.children[2] as HTMLInputElement;
        if (!input) return void Logger.info('Launcher input not found.');

        Logger.info('Launcher input found.', input);
        input.onkeydown = e => {
            if (e.key === 'Escape') {
                e.preventDefault();
                e.stopImmediatePropagation();
                return void this.cancel();
            }

            if (e.key === 'Enter' && this.options[0]?.value) {
                e.preventDefault();
                e.stopImmediatePropagation();
                return void this.getOptions('', this.options[0].value);
            }

            if (e.key === ' ' && e.ctrlKey && e.shiftKey) {
                e.preventDefault();
                e.stopImmediatePropagation();
                delete Launcher._instance;
                return void this.cancel();
            }

            return true;
        };

        input.focus();
    }

    public static initGestureControl() {
        if (!GlobalFlags.isMobile) return;

        if (isDevMode()) new NotifyUserMessage('launcher.enabling-gesture-support');

        let currentTouches = {} as Record<string, Touch>;
        let eventName = { touchstart: 'touchstart', touchend: 'touchend' };

        if ((window?.navigator as any).msPointerEnabled) {
            eventName = { touchstart: 'MSPointerDown', touchend: 'MSPointerUp' };
        }

        document.addEventListener(eventName.touchstart, (touchEvent: TouchEvent) => {
            const touches = touchEvent.touches || [touchEvent];
            let touch: Touch;
            for (let i = 0, l = touches.length; i < l; i++) {
                touch = touches[i];
                currentTouches[touch.identifier || (touch as any).pointerId] = touch;
            }
        });

        document.addEventListener(
            eventName.touchend,
            (touchEvent: TouchEvent) => {
                const touchCount = Object.keys(currentTouches).length;
                currentTouches = {};
                if (touchCount !== 4) return;
                if (!ApplicationState.getSetting<boolean>('global.mobile-launcher-enabled', false)) {
                    if (isDevMode()) new NotifyUserMessage('launcher.is-disabled');
                    return;
                }
                if ((ApplicationState.subscription?.product?.productLevel || 0) < 2) {
                    if (isDevMode()) new NotifyUserMessage('launcher.not-advanced-or-above');
                    return;
                }

                touchEvent.preventDefault();
                Launcher.toggle();
            },
            false
        );
    }
}
