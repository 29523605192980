import type { Customer, Frequency, Job } from '@nexdynamic/squeegee-common';
import { JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { AssignmentService } from '../Users/Assignees/AssignmentService';
import type { IIndicator } from './IIndicator';
import { JobService } from './JobService';
import { JobActions } from './actions/JobActions';

export class JobSummary {
    public avatar = '?';
    public indicators: Array<IIndicator> = [];
    public servicesDescription: string;
    public roundsDescription: string;
    public job: Job;
    public customer: Customer;
    public frequency: Frequency | undefined;
    public distance: number;
    public avatarColour = '';
    protected today = moment().format('YYYY-MM-DD');
    public scheduleItemsArray: Array<ScheduleItem> = [];

    private _assignees?: string;

    private limit = 3;
    private _limitedScheduleItemsArray?: Array<ScheduleItem>;
    @computedFrom('_limitedScheduleItemsArray')
    public get limitedScheduleItemsArray(): Array<ScheduleItem> | undefined {
        if (!this.scheduleItemsArray) return;
        if (this._limitedScheduleItemsArray === undefined) {
            if (!this.scheduleItemsArray || this.showAll || this.scheduleItemsArray.length < this.limit) {
                this._limitedScheduleItemsArray = this.scheduleItemsArray;
            } else {
                const beforeToday = this.scheduleItemsArray.filter(x => x.date < this.today);
                const today = this.scheduleItemsArray.filter(x => x.date === this.today);
                const afterToday = this.scheduleItemsArray.filter(x => x.date > this.today);
                const items = beforeToday.concat(today.concat(afterToday.slice(0, 1))).slice(-(this.limit + today.length));
                this._limitedScheduleItemsArray = items;
            }
        }
        return this._limitedScheduleItemsArray;
    }

    public get showAll() {
        return ApplicationState.showAllJobHistory(this.job._id);
    }

    protected toggleShowAll() {
        ApplicationState.toggleShowAllJobHistory(this.job._id);
        this.refresh();
    }

    public refresh() {
        this._limitedScheduleItemsArray = undefined;
    }

    private _completedCount?: number;
    private _skippedCount?: number;
    private _overdueCount?: number;
    private _invoicedCount?: number;

    public get completedCount(): number {
        if (this._completedCount) return this._completedCount;
        this._completedCount = this.scheduleItemsArray.filter(x => x.status === JobOccurrenceStatus.Done).length;
        return this._completedCount;
    }

    public get skippedCount(): number {
        if (this._skippedCount) return this._skippedCount;
        this._skippedCount = this.scheduleItemsArray.filter(x => x.status === JobOccurrenceStatus.Skipped).length;
        return this._skippedCount;
    }

    public get overdueCount(): number {
        if (this._overdueCount) return this._overdueCount;
        this._overdueCount = this.scheduleItemsArray.filter(
            s => s.date < moment().format('YYYY-MM-DD') && s.status === JobOccurrenceStatus.NotDone
        ).length;
        return this._overdueCount;
    }

    public get invoicedCount(): number {
        if (this._invoicedCount) return this._invoicedCount;
        this._invoicedCount = this.scheduleItemsArray.filter(x => x.occurrence.paymentStatus === 'invoiced').length;
        return this._invoicedCount;
    }

    @computedFrom('job', 'frequency')
    public get nextDueDate() {
        if (!this.job.date) return ApplicationState.localise('jobs.next-due-no-date');
        const date = JobService.getJobNextDueDate(this.job);
        return date ? moment(date).format('ddd Do MMM YYYY') : '';
    }

    public viewJobSummary() {
        JobActions.view(this.customer, this.job);
    }

    public viewAppointments() {
        JobActions.viewAppointments(this.customer._id, this.job._id);
    }

    @computedFrom('job')
    public get assignee() {
        if (!this._assignees) {
            this._assignees = AssignmentService.getAssignees(this.job)
                .map(x => x.name)
                .join(', ');
        }
        return this._assignees;
    }

    public async refreshExternalCalendarFeed() {
        try {
            const result = await JobService.syncExternalJobCalendarEvents(this.job._id);

            new NotifyUserMessage(ApplicationState.localise('jobs.refresh-external-calendar-success', { count: result.toString() }));
        } catch (error) {
            new NotifyUserMessage(ApplicationState.localise('jobs.refresh-external-calendar-failed'));
        }
    }
}
