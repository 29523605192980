import type { IDownload, TCellValue, TranslationKey } from '@nexdynamic/squeegee-common';
import { TableData } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import type { IFabAction } from './Fabs/IFabAction';

export class ResponsiveTableCustomElement {
    @bindable() report: Array<TableData>;
    @bindable() reportTitle: TranslationKey = 'general.unknown';
    @bindable() disableDownloads: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) updateFab?: (fabActions: Array<IFabAction>) => void;
    @bindable() allowHtml = false;
    @bindable() autoFilterColumns: Array<string>;

    protected reportPage = 0;
    protected data: TableData;
    @bindable() sortBy = '';
    @bindable() sortAscending = true;

    public async attached() {
        this.data = this.report && this.report[this.reportPage];
        if (this.data && this.data.name) this.reportTitle = this.data.name as TranslationKey;
        this._csvData = null;
        this.sort();
        this.updateFabActions();
    }

    protected rows: Array<Array<TCellValue>>;

    protected async sort(headerCell?: string) {
        if (this.data && this.data.rows && this.data.rows.length) {
            this.sortBy = headerCell || this.sortBy;

            if (headerCell && headerCell === this.sortBy) this.sortAscending = !this.sortAscending;
            const index = this.data.header.indexOf(this.sortBy);
            const sortNumeric = this.data.rows.map(x => x[index]).every(x => !isNaN(Number(x || 0)));
            this.data.rows.sort((x, y) => {
                const a = sortNumeric ? Number(x[index] || 0) : (x[index] || '').toString();
                const b = sortNumeric ? Number(y[index] || 0) : (y[index] || '').toString();

                return a > b ? (this.sortAscending ? 1 : -1) : a < b ? (this.sortAscending ? -1 : 1) : 0;
            });
            this.filter();
        }
    }

    private _autoFilters = {} as { [header: string]: false | Array<{ value: string; text: string }> };
    protected autoFiltered(header: string) {
        let filter = this._autoFilters[header];
        if (filter === false) return;
        if (filter) return filter;

        filter = this.getFilter(header);
        this._autoFilters[header] = filter;

        return filter;
    }
    private getFilter(header: string): false | { value: string; text: string }[] {
        if (
            !this.autoFilterColumns ||
            this.autoFilterColumns.map(h => (h || '').trim().toLowerCase()).indexOf((header || '').trim().toLowerCase()) === -1
        )
            return false;

        const filterValues = {} as { [value: string]: number };
        const headerIndex = this.data.header.indexOf(header);
        for (const row of this.data.rows) {
            const value = row[headerIndex].toString();
            if (filterValues[value] === undefined) filterValues[value] = 1;
            else filterValues[value]++;
        }

        const values = Object.keys(filterValues);
        if (values.length <= 1) return false;

        return [{ value: '', text: `${header} (all)` } as { value: string; text: string }].concat(
            Object.keys(filterValues).map(value => ({ value, text: `${value} (${filterValues[value].toFixed(0)})` }))
        );
    }

    protected appliedFilters = {} as { [header: string]: string };
    protected filter() {
        this.rows = this.data.rows;

        const headerFilters = Object.keys(this.appliedFilters).map(header => ({ header, index: this.data.header.indexOf(header) }));
        this.rows = this.rows.filter(row => {
            for (const headerFilter of headerFilters) {
                const filter = this.appliedFilters[headerFilter.header];
                if (!filter) continue;
                if (row[headerFilter.index] !== filter) return false;
            }
            return true;
        });
    }

    private async updateFabActions() {
        if (!this.updateFab) return;

        const fabActions: Array<IFabAction> = [];

        if (this.csvAllData) {
            const csvName = `${ApplicationState.localise(this.reportTitle)}.csv`;
            const link = await this.getCsvDownloadUrl(<string>this.csvAllData, csvName);
            const handler = link ? undefined : () => new NotifyUserMessage('downloads.unable-to-create-message');
            fabActions.push({
                tooltip: 'download.as-csv',
                actionType: 'action-download-report',
                handler,
                link,
                download: csvName,
                roles: [],
            });
        }

        this.updateFab(fabActions);
    }

    protected async getCsvDownloadUrl(csvContent: string, csvName: string) {
        const download: IDownload = {
            name: csvName,
            mimeType: 'text/csv;charset=utf-8',
            content: csvContent,
        };
        const response = await Api.post<{ id: string }>(Api.apiEndpoint, '/api/download', download);
        if (!response || !response.data || !response.data.id) return;

        return `${Api.apiEndpoint}/go/d/${response.data.id}`;
    }
    @computedFrom('data')
    protected get tablePageTitle(): TranslationKey {
        return ApplicationState.localise('general.page-x-of-y', { x: (this.reportPage + 1).toString(), y: this.report.length.toString() });
    }

    protected get hasRowClickHandler() {
        return !!this.data && !!this.data.rowClickHandler;
    }

    protected rowClickHandler(row: Array<TCellValue>) {
        this.data && this.data.rowClickHandler && this.data.rowClickHandler(row);
    }

    protected previous() {
        if (this.reportPage > 0) this.switchReportIndex(this.reportPage - 1);
    }

    protected next() {
        if (this.report.length - 1 > this.reportPage) this.switchReportIndex(this.reportPage + 1);
    }

    protected switchReportIndex(index: number) {
        this.reportPage = index;
        this.data = this.report && this.report[this.reportPage];
        if (this.data && this.data.name) this.reportTitle = this.data.name as TranslationKey;
        this.sortBy && this.sort();
        this._csvData = null;
        this.updateFabActions();
    }

    protected get showDownloads() {
        return !this.disableDownloads && (!!this._csvAllData || !!this.csvSummary || !!this.csvData);
    }

    private _csvAllData: string | null;
    protected get csvAllData() {
        if (this._csvAllData === undefined) {
            if (!this.report || !this.report.length) {
                this._csvAllData = null;
            } else {
                const data: Array<Array<TCellValue>> = [];
                data.push(this.report[0].header);
                for (const group of this.report) {
                    for (const row of group.rows) {
                        data.push(row);
                    }
                }
                this._csvAllData = data.length ? TableData.tableSectionToCsv(data) : null;
            }
        }
        return this._csvAllData;
    }

    private _csvSummary: string | null;
    protected get csvSummary() {
        if (this._csvSummary === undefined) {
            if (!this.report || !this.report.length) {
                this._csvSummary = null;
            } else {
                const data: Array<Array<TCellValue>> = [];
                for (const group of this.report) {
                    if (group.footer && !!group.footer.length) {
                        for (const r of group.footer) {
                            const row = r.slice();
                            while (row.length && typeof row[0] === 'string' && !(<string>row[0]).trim().length) row.shift();
                            data.push(row);
                        }
                    }
                }
                this._csvSummary = data.length ? TableData.tableSectionToCsv(data) : null;
            }
        }
        return this._csvSummary;
    }

    private _csvData: string | null | undefined;
    protected get csvData() {
        if (this._csvData === undefined) {
            if (!this.data) {
                this._csvData = null;
            } else {
                const data: Array<Array<TCellValue>> = [];
                data.push(this.data.header);
                for (const row of this.data.rows) {
                    data.push(row);
                }
                this._csvData = data.length ? TableData.tableSectionToCsv(data) : null;
            }
        }
        return this._csvData;
    }
}
