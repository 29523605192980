import type {
    Customer, CustomerNotificationMethodTypes,
    Service,
    SqueegeeRecord
} from '@nexdynamic/squeegee-common';
import {
    FrequencyType,
    FrequencyUtils,
    Job,
    JobGroup,
    JobOccurrence,
    JobOccurrenceStatus,
    Location,
    Tag,
    TagType,
    copyObject,
    to2dp
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import type { ParseError, ParseMeta } from 'papaparse';
import { parse } from 'papaparse';
import { ApplicationState } from '../ApplicationState';
import { CustomerService } from '../Customers/CustomerService';
import { AssignmentService } from '../Users/Assignees/AssignmentService';
import { UserService } from '../Users/UserService';
import { Data } from './Data';

export class SqueegeeParser {
    private static INPUT_DATE_FORMAT = 'YYYY-MM-DD';
    private static OUTPUT_DATE_FORMAT = 'YYYY-MM-DD';

    public static parseCSV<TDataType>(csv: string) {
        return <{ errors: Array<ParseError>; meta: ParseMeta; data: Array<TDataType> }>parse(csv, { header: true, skipEmptyLines: true });
    }


    public static convertRecord(
        jobOrder: Array<string>,
        record: SqueegeeRecord,
        rounds: { [id: string]: JobGroup },
        services: { [id: string]: Tag },
        customers: Array<Customer>,
        defaultService: Service,
        targetCustomer?: Customer
    ) {
        let customer: Customer | undefined;
        let isNewCustomer = false;
        if (!targetCustomer) {
            const email = SqueegeeParser.getValue(record, 'customer email');
            if (email === 'sample-import@squeeg.ee') throw 'Sample customer in data, remove this before importing';

            const externalId = SqueegeeParser.getValue(record, 'external id');

            if (!customer && externalId) {
                customer = customers.find(x => x._externalId === externalId);
                if (!customer) {
                    customer = Data.firstOrDefault<Customer>('customers', x => x._externalId === externalId);
                }
            }
            isNewCustomer = !customer;

            if (!customer) {
                customer = CustomerService.create();
                customer._externalId = externalId;
            }

            customer.name = SqueegeeParser.getValue(record, 'customer name', customer.name);
            customer.address.addressDescription = SqueegeeParser.getValue(record, 'customer address', customer.address.addressDescription);
            if (!customer.address.isVerified) {
                const lat = Number(SqueegeeParser.getValue(record, 'customer latitude'));
                const lng = Number(SqueegeeParser.getValue(record, 'customer longitude'));
                if (lat && lng) {
                    customer.address.lngLat = [lng, lat];
                    customer.address.isVerified = true;
                    customer.address.lngLatSource = 'import';
                }
            }
            customer.telephoneNumber = SqueegeeParser.getValue(record, 'customer phone', customer.telephoneNumber || '');
            customer.telephoneNumberOther = SqueegeeParser.getValue(record, 'customer phone other', customer.telephoneNumberOther || '');
            customer.email = SqueegeeParser.getValue(record, 'customer email', customer.email || '');
            customer.notes = (customer.notes ? `${customer.notes + '\n'}` : '') + SqueegeeParser.getValue(record, 'customer notes', '');
            customer.source = SqueegeeParser.getValue(record, 'customer source', customer.source || '');
            customer.defaultNotificationMethod = SqueegeeParser.getValue(
                record,
                'customer default notification',
                customer.defaultNotificationMethod || ''
            ) as CustomerNotificationMethodTypes;
            customer.state = SqueegeeParser.getValue(record, 'customer inactive', customer.state === 'inactive' ? 'true' : 'false').toLowerCase() === 'true' ? 'inactive' : 'active';


            const marketingAccepted = SqueegeeParser.getValue(record, 'customer marketing accepted', customer.marketingAccepted ? 'y' : 'n').toLowerCase();
            customer.marketingAccepted = marketingAccepted === 'y' || marketingAccepted === 'yes' || marketingAccepted === 'true' || marketingAccepted === '1';
        } else {
            customer = targetCustomer;
        }
        const jobsAndOccurrences = SqueegeeParser.getJobs(jobOrder, record, customer, rounds, services, defaultService);
        customer.jobs = Object.assign((customer.jobs || {}), jobsAndOccurrences.jobs);

        return { customer, isNewCustomer, jobOccurrences: jobsAndOccurrences.lastDoneOccurrences };
    }

    private static getValue(record: SqueegeeRecord, key: keyof SqueegeeRecord, defaultValue = '') {
        (<any>key) = key.trim();
        if (record[key] && record[key].trim()) return record[key].trim() || defaultValue;
        return defaultValue;
    }

    private static getJobs(
        jobOrder: Array<string>,
        record: SqueegeeRecord,
        customer: Customer,
        rounds: { [id: string]: JobGroup },
        services: { [id: string]: Tag },
        defaultService: Service
    ) {
        const jobs: { [id: string]: Job } = {};
        const lastDoneOccurrences: Array<JobOccurrence> = [];

        const existingJobs = Object.values(customer.jobs || {});
        // Only include the job if there is any data for it.
        const includeJob = Boolean(
            SqueegeeParser.getValue(record, 'job reference') ||
            SqueegeeParser.getValue(record, 'job address') ||
            SqueegeeParser.getValue(record, 'job description') ||
            SqueegeeParser.getValue(record, 'job price') ||
            SqueegeeParser.getValue(record, 'job date') ||
            SqueegeeParser.getValue(record, 'job frequency interval') ||
            SqueegeeParser.getValue(record, 'job frequency type') ||
            SqueegeeParser.getValue(record, 'job day of week') ||
            SqueegeeParser.getValue(record, 'job week of month') ||
            SqueegeeParser.getValue(record, 'job time') ||
            SqueegeeParser.getValue(record, 'job service') ||
            SqueegeeParser.getValue(record, 'job round') ||
            SqueegeeParser.getValue(record, 'job duration') ||
            SqueegeeParser.getValue(record, 'job assignee') ||
            SqueegeeParser.getValue(record, 'job last done')
        );

        if (!includeJob) return { jobs, lastDoneOccurrences };

        const jobRef = SqueegeeParser.getValue(record, 'job reference');
        let job = existingJobs.find(job => job._externalId && job._externalId === jobRef);

        if (!job && (!SqueegeeParser.getValue(record, 'job date') && !customer)) return { jobs, lastDoneOccurrences };



        const jobAddressDescription = SqueegeeParser.getValue(record, 'job address');
        let jobLocation: Location;
        if (!jobAddressDescription) {
            jobLocation = copyObject(customer.address);
        } else {
            jobLocation = new Location();
            jobLocation.addressDescription = jobAddressDescription;

            const lat = Number(SqueegeeParser.getValue(record, 'job latitude')) || undefined;
            const lng = Number(SqueegeeParser.getValue(record, 'job longitude'));
            if (lat && lng) {
                jobLocation.lngLat = [lng, lat];
                jobLocation.isVerified = true;
                jobLocation.lngLatSource = 'import';
            }
        }

        if (!job) {
            job = new Job(customer._id, undefined, undefined, undefined, jobLocation, undefined, undefined, undefined, undefined, 1, FrequencyType.NoneRecurring);
            job._externalId = jobRef
            jobOrder.push(job._id);
        } else {
            if (!job.location) job.location = jobLocation;
            if (jobAddressDescription) job.location.addressDescription = jobAddressDescription;
        }

        job.description = (job.description ? `${job.description + '\n'}` : '') + SqueegeeParser.getValue(record, 'job description', '');
        const price = SqueegeeParser.getValue(record, 'job price', job.price?.toFixed(2));
        job.price = price ? to2dp(parseFloat(price)) : 0;

        const date = SqueegeeParser.getValue(record, 'job date', undefined);

        if (!date) {
            job.date = undefined
        } else {
            // TODO: Make sure this sets the day of week on the job to the same as the job date.
            job.date = moment(date, SqueegeeParser.INPUT_DATE_FORMAT).format(SqueegeeParser.OUTPUT_DATE_FORMAT);
        }

        const intervalString = (SqueegeeParser.getValue(record, 'job frequency interval') as string) || '';
        if (intervalString) {
            const interval = parseInt((intervalString || '0').trim());
            const typeAndInterval = SqueegeeParser.getFrequencyType(
                interval,
                SqueegeeParser.getValue(record, 'job frequency type', 'non-recurring'),
                SqueegeeParser.getValue(record, 'job day of week'),
                SqueegeeParser.getValue(record, 'job week of month')
            );
            job.frequencyInterval = typeAndInterval.interval;
            job.frequencyType = typeAndInterval.frequencyType;
            job.frequencyDayOfWeek = FrequencyUtils.getDayOfWeekFromText(SqueegeeParser.getValue(record, 'job day of week') || '');
            job.frequencyDayOfMonth = FrequencyUtils.getDayOfMonthFromText(
                SqueegeeParser.getValue(record, 'job day of month') || ''
            );
            job.frequencyWeekOfMonth = FrequencyUtils.getWeekOfMonthFromText(
                SqueegeeParser.getValue(record, 'job week of month') || ''
            );
        } else if (!job.frequencyType) {
            job.frequencyType = FrequencyType.NoneRecurring;
        }

        job.time = SqueegeeParser.getValue(record, 'job time')
            ? moment(SqueegeeParser.getValue(record, 'job time'), 'HH:mm').format('HH:mm')
            : undefined;

        job.services = SqueegeeParser.getValue(record, 'job service')
            ? SqueegeeParser.getService(record, services, defaultService)
            : job.services;

        if (!job.services?.length) job.services = [copyObject(defaultService)];

        job.rounds = SqueegeeParser.getValue(record, 'job round')
            ? SqueegeeParser.getRounds(record, rounds)
            : job.rounds;

        const durationText = SqueegeeParser.getValue(record, 'job duration', job.duration);
        let duration: string | undefined;
        if (durationText) {
            const minutes = Number(durationText);
            if (!isNaN(minutes)) {
                duration = `${Math.floor(minutes / 60)}:${minutes % 60}`;
            } else if (/\d\d?\:\d\d?/.test(durationText)) {
                duration = durationText;
            }
        }

        job.duration = duration ? duration : ApplicationState.account.defaultJobDuration || '00:30';

        const assigneeField = SqueegeeParser.getValue(record, 'job assignee');
        if (assigneeField) {
            const assignee = UserService.getUser(assigneeField);
            if (assignee) AssignmentService.assign(job, assignee._id);
        }

        jobs[job._id] = job;

        const lastDone = SqueegeeParser.getValue(record, 'job last done');
        if (lastDone && moment(lastDone).isValid()) {
            const occurrence = new JobOccurrence(job, lastDone, JobOccurrenceStatus.Done, lastDone);
            if (!Data.get(occurrence._id)) {
                occurrence.paymentStatus = 'unbillable';
                lastDoneOccurrences.push(occurrence);
            }
        }

        return { jobs, lastDoneOccurrences };
    }

    public static getFrequencyType(
        interval?: number,
        type?:
            | 'day'
            | 'days'
            | 'daily'
            | 'week'
            | 'weeks'
            | 'weekly'
            | 'month'
            | 'months'
            | 'monthly'
            | 'year'
            | 'years'
            | 'yearly'
            | 'non-recurring'
            | ''
            | string,
        dayOfWeek?: string,
        weekOfMonth?: string
    ): { interval: number; frequencyType: FrequencyType } {
        if (interval === undefined) interval = 0;

        const frequency = (type || '').trim().toLowerCase();
        switch (frequency) {
            case 'day':
            case 'days':
            case 'daily':
                return { interval, frequencyType: FrequencyType.Days };
            case 'week':
            case 'weeks':
            case 'weekly':
                return { interval, frequencyType: FrequencyType.Weeks };
            case 'month':
            case 'months':
            case 'monthly':
                if (dayOfWeek && dayOfWeek !== '' && weekOfMonth && weekOfMonth !== '')
                    return { interval, frequencyType: FrequencyType.MonthlyDayOfWeek };
                else return { interval, frequencyType: FrequencyType.DayOfMonth };
            case 'year':
            case 'years':
            case 'yearly':
                return { interval: interval * 12, frequencyType: FrequencyType.DayOfMonth };
            default:
                return { interval, frequencyType: FrequencyType.NoneRecurring };
        }
    }

    private static getRounds(record: SqueegeeRecord, rounds: { [id: string]: JobGroup }) {
        const newTagDescription = SqueegeeParser.getValue(record, 'job round');
        if (newTagDescription && rounds[newTagDescription] === undefined) {
            const newRound = new JobGroup(newTagDescription);
            rounds[newRound.description] = newRound;
            return [newRound];
        } else if (newTagDescription) {
            return [rounds[newTagDescription]];
        } else {
            return [];
        }
    }

    private static getService(record: SqueegeeRecord, services: { [id: string]: Tag }, defaultService: Service) {
        const newTagDescription: string = SqueegeeParser.getValue(record, 'job service') || '';
        const servicesValues = newTagDescription
            .split(',')
            .map((x: string) => {
                return x.trim();
            })
            .filter((x: string) => {
                return !!x;
            });

        if (!servicesValues.length) return [defaultService];

        const serviceArray: Array<Tag> = [];

        for (const serviceValue of servicesValues) {
            let service = services[serviceValue];
            if (service === undefined) {
                service = new Tag(serviceValue, TagType.SERVICE);
                services[service.description] = service;
            }
            serviceArray.push(service);
        }

        return serviceArray;
    }


}
