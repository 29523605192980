import { SyncMode, wait } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { Prompt } from '../Dialogs/Prompt';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { unknownLengthBackgroundTask } from '../Utilities';
import { Data } from './Data';
import { backupServer } from './backupServer';

export const createBackup = async () => {
    new LoaderEvent(true, true, 'backups.performing-server-side-backup');
    const { done } = unknownLengthBackgroundTask('backups.performing-server-side-backup');
    try {
        const backgrounded = setTimeout(() => {
            new NotifyUserMessage('backups.performing-server-side-backup');
            new LoaderEvent(false);
        }, 2000);

        const result = await Api.post(backupServer(), '/api/backups', undefined, undefined, false, { timeout: 600000 }).catch(() => false);

        new LoaderEvent(false);

        if (!result) {
            clearTimeout(backgrounded);
            new Prompt('general.error', 'settings.backups-error', { cancelLabel: '' }).show();
            return false;
        }
    } finally {
        done();
    }

    new NotifyUserMessage('settings.backup-created');
    return true;
};

export const restoreBackup = async (backup: { id: string; date: string }) => {
    try {
        if (!RethinkDbAuthClient.session) return;

        // Confirm restore backup
        const areYouSure = new Prompt('prompts.confirm-server-restore', 'prompts.confirm-server-restore-text', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
            localisationParams: {
                date: moment(backup.date).format('dddd MMMM Do, h:mma'),
            },
        });
        const youAreSure = await areYouSure.show();
        if (!youAreSure) return;

        new LoaderEvent(true, true, 'settings.restoring-backup-on-server');

        await Api.post(backupServer(), `/api/backups/${backup.id}/restore`, undefined, undefined, false, { timeout: 600000 });

        new NotifyUserMessage('settings.backup-restore-syncing');
        await wait(5000);
        await Data.fullSync(SyncMode.FullClearCache);
        new LoaderEvent(false);
        await new Prompt('prompts.success-title', 'prompts.success-restore-backup-text', { cancelLabel: '' }).show();
        window.location.reload();
    } catch (error) {
        Logger.error('Failed to restore backup', { error });
    }
};
