import { ApplicationState } from './ApplicationState';

export class Features {
    public get scheduledMessaging() {
        return ApplicationState.hasUltimateOrAbove;
    }
    public get audit() {
        return ApplicationState.getSetting('beta-features.audit', false);
    }
    public get visualPlanner() {
        return ApplicationState.hasUltimateOrAbove;
    }
    public get timeTracker() {
        return ApplicationState.getSetting('beta-features.time-tracker', false);
    }
    public get marketplaceBuyer() {
        return true;
    }
    public get marketplaceSeller() {
        return ApplicationState.getSetting('beta-features.marketplace-seller', false);
    }
    public get send() {
        return ApplicationState.hasUltimateOrAbove;
    }
    public get balance() {
        return ApplicationState.getSetting('beta-features.balance', false);
    }
    public get pricing() {
        return ApplicationState.hasUltimateOrAbove;
    }
    public get dnaPayments() {
        return ApplicationState.getSetting('beta-features.dna-payments', false);
    }
    public get ServiceTagging() {
        return ApplicationState.hasUltimateOrAbove;
    }
    public get availability() {
        return ApplicationState.getSetting('beta-features.availability', false);
    }
    public get SquarePayments() {
        return ApplicationState.getSetting('beta-features.square-payments', false);
    }
    public get transferData() {
        return ApplicationState.getSetting('beta-features.transfer-data', false);
    }
}
