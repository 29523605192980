import type {
    Customer,
    PaymentAccount,
    TMatcher,
    Transaction,
    TransactionProvider,
    TransferTransaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    Expense,
    ExpenseRefund,
    MatchingUtils,
    PaymentTransaction,
    RefundTransaction,
    TransactionType,
    sortByDateAsc,
    wait,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { ChargeCustomerService } from '../../Charge/ChargeCustomerService';
import { AccountsLedgerService } from '../../ChartOfAccounts/AccountsLedgerService';
import { ConnectedServicesService } from '../../ConnectedServices/ConnectedServicesService';
import { MatcherService } from '../../ConnectedServices/MatcherService';
import { CustomerCreditDialog } from '../../Customers/Components/CustomerCreditDialog';
import { CustomerRefundDialog } from '../../Customers/Components/CustomerRefundDialog';
import { CustomerService } from '../../Customers/CustomerService';
import { Data } from '../../Data/Data';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { ExpenseFormDialog } from '../../Expenses/Components/ExpenseFormDialog';
import { InvoiceService } from '../../Invoices/InvoiceService';
import { Logger } from '../../Logger';
import { MatchPayment } from './MatchPayment';
import type { IMatcherEngineDataSources } from './MatcherEngine';

export interface IMatchMap {
    ignored: Record<string, 'ignored'>;
    matched: Record<string, Transaction>;
    unmatched: Record<string, Array<Transaction>>;
}

export class MatchPaymentService {
    static getPaymentsUnallocated(paymentAccountId: string, provider: TransactionProvider) {
        return Data.all<Transaction & Expense>(
            'transactions',
            t =>
                !t.voidedId &&
                t.transactionType !== TransactionType.Unreconciled &&
                !t.externalIds?.[provider] &&
                (t.paymentDetails?.paymentAccountId === paymentAccountId || t.paymentAccountId === paymentAccountId)
        );
    }

    static getPayments(paymentAccountId: string) {
        return Data.all<Transaction & Expense>(
            'transactions',
            t =>
                !t.voidedId &&
                t.transactionType !== TransactionType.Unreconciled &&
                (t.paymentDetails?.paymentAccountId === paymentAccountId || t.paymentAccountId === paymentAccountId)
        );
    }

    static async refreshSuggestions(args: { items: Array<MatchPayment>; dontAutoMatch?: boolean; targetData: IMatcherEngineDataSources }) {
        // Get expenses, payments and transfers that are not of the unallocated type to match with

        const itemsToReturn = [];

        for (let index = 0; index < args.items.length; index++) {
            await wait(1);
            const item = args.items[index];
            if (!item) continue;
            if (item.loaded) continue;
            if (!args.dontAutoMatch && !item.dontAutoMatch) this.autoMatchItem(item, args.targetData.payments);
            if (!item.match) item.refreshMatchSuggestions(args.targetData.customers, args.targetData.invoices, args.targetData.payments);

            item.refreshMatchers();
            itemsToReturn.push(item);
        }

        return itemsToReturn;
    }

    /* Is a transfer match if the existing transaction 
        is a transfer, 
        its amount is equal to provider
        it is within 5 days */
    static isTransferMatch(providerTran: Transaction, bTran: Transaction) {
        if (bTran.transactionType !== TransactionType.Transfer) return 0;
        const dateDiff = this.getDateDistanceInDays(providerTran, bTran);
        if (dateDiff > 5) return 0;
        return 0.9 - dateDiff / 10;
    }

    static isPaymentMatch(providerTran: Transaction, bTran: Transaction, customer?: Customer) {
        if (bTran.transactionType !== TransactionType.Expense && bTran.transactionType !== TransactionType.Payment) return 0;
        const dateDiff = this.getDateDistanceInDays(providerTran, bTran);
        if (dateDiff > 3) return 0;
        let amountOfMatch = 0.9 - dateDiff / 10;
        if (amountOfMatch < 0.15) {
            let customerName = ''; // Remove this line
            let customerAddress = '';

            if (customer?.name) {
                customerName = customer.name; // Remove this line
            }
            if (customer?.address?.addressDescription) {
                customerAddress = customer.address.addressDescription; // Remove this line
            }
            const customerMatch = MatchingUtils.termCompare(providerTran.description || '', customerName);
            if (customerMatch > amountOfMatch) amountOfMatch = customerMatch;
            const addressMatch = MatchingUtils.termCompare(providerTran.description || '', customerAddress);
            if (addressMatch > amountOfMatch) amountOfMatch = addressMatch;
        }
        return amountOfMatch;
    }

    static isPreviousMatch(providerTran: Transaction, bTran: Transaction, providerId: TransactionProvider, customer?: Customer) {
        if (!bTran.externalIds) return 0;
        if (!bTran.externalIds[providerId]) return 0;
        if (bTran.amount !== providerTran.amount) return 0;
        if (this.getDateDistanceInDays(providerTran, bTran) > 1) return 0;
        if (bTran.statementDescription && bTran.statementDescription === providerTran.description) return 1;

        let amountOfMatch = MatchingUtils.termCompare(providerTran.description || '', bTran.statementDescription || '');

        if (amountOfMatch < 0.15) {
            let customerName = ''; // Remove this line
            let customerAddress = '';

            if (customer?.name) {
                customerName = customer.name; // Remove this line
            }
            if (customer?.address?.addressDescription) {
                customerAddress = customer.address.addressDescription; // Remove this line
            }
            const customerMatch = MatchingUtils.termCompare(providerTran.description || '', customerName);
            if (customerMatch > amountOfMatch) amountOfMatch = customerMatch;
            const addressMatch = MatchingUtils.termCompare(providerTran.description || '', customerAddress);
            if (addressMatch > amountOfMatch) amountOfMatch = addressMatch;
        }

        return 0.9 + amountOfMatch * 0.1;
    }

    static isDuplicatedPayment(paymentA: PaymentTransaction | Expense, paymentB: PaymentTransaction | Expense) {
        if (paymentA._id === paymentB._id) return 0;
        if (paymentB.amount !== paymentA.amount) return 0;
        if (this.getDateDistanceInDays(paymentA, paymentB) > 1) return 0;
        if (paymentA.customerId && paymentB.customerId !== paymentA.customerId) return 0;
        if (paymentA.statementDescription && paymentA.statementDescription === paymentB.statementDescription) return 1;
        if (MatchingUtils.termCompare(paymentA.statementDescription || '', paymentB.statementDescription || '') < 0.5) return 0;
        return 1;
    }

    static getDateDistanceInDays(providerTran: Transaction, bTran: Transaction) {
        const providerDate = new Date(providerTran.date);
        const bDate = new Date(bTran.date);
        const dateDistanceInMs = Math.abs(providerDate.getTime() - bDate.getTime());
        return dateDistanceInMs / 86400000;
    }
    static autoMatchItem(item: MatchPayment, paymentTransactions: Readonly<Array<Expense & Transaction>>) {
        let matchFound = false;
        for (const p of paymentTransactions) {
            if (p.amount !== item.providerPayment.amount) continue;
            const customer = p.customerId ? Data.get<Customer>(p.customerId) : undefined;
            const previousMatchPercent = MatchPaymentService.isPreviousMatch(item.providerPayment, p, item.provider, customer);
            if (previousMatchPercent >= 0.91) {
                item.match = p;
                matchFound = true;
                item.matchPercent = previousMatchPercent;
                continue;
            }

            const pmtMatchPercentage = MatchPaymentService.isPaymentMatch(item.providerPayment, p, customer);
            if (pmtMatchPercentage) {
                item.possiblePaymentMatches.push({
                    transaction: p,
                    matchesOn: ['amount', 'other', 'date'],
                    matchPercent: pmtMatchPercentage,
                });
                //matchFound = true;
                continue;
            }
            const transferMatchPercentage = MatchPaymentService.isTransferMatch(item.providerPayment, p);
            if (transferMatchPercentage) {
                item.type = p.amount < 0 ? 'Transfer In From' : 'Transfer Out To';
                item.match = p;
                item.matchPercent = transferMatchPercentage;
                matchFound = true;
            }
        }
        if (item.possiblePaymentMatches.length > 1) {
            item.possiblePaymentMatches.sort((a, b) => b.matchPercent - a.matchPercent);
        }

        return matchFound;
    }

    static matchTransfersWithDifferentCurrency(item: MatchPayment, paymentTransactions: Readonly<Array<Expense & Transaction>>) {
        if (item.match) return;
        for (const p of paymentTransactions) {
            if (p.transactionType !== TransactionType.Transfer) continue;
            if (p.date.substring(0, 10) !== item.providerPayment.date.substring(0, 10)) continue;

            item.type = p.amount < 0 ? 'Transfer In From' : 'Transfer Out To';
            item.match = p;
            return true;
        }
    }

    static async autoMatch(items: Array<MatchPayment>, paymentTransactions: Readonly<Array<Expense & Transaction>>, mode?: 'transfers') {
        const itemsThatNeedMatching = items.filter(m => m.status === 'unmatched');

        const paymentsToAutomatch = [];

        console.log(`Auto matching ${itemsThatNeedMatching.length} items`);
        let count = 0;
        for (const item of itemsThatNeedMatching) {
            count++;

            if (mode === 'transfers') {
                if (MatchPaymentService.matchTransfersWithDifferentCurrency(item, paymentTransactions)) {
                    paymentsToAutomatch.push(item);
                }
            } else {
                if (MatchPaymentService.autoMatchItem(item, paymentTransactions)) {
                    paymentsToAutomatch.push(item);
                }
            }

            if (count % 1000 === 0) {
                await wait(1);
                new LoaderEvent(
                    true,
                    true,
                    `Auto matched ${count} of ${itemsThatNeedMatching.length} (${paymentsToAutomatch.length} matches)` as TranslationKey
                );
                console.log(`Auto matched ${count} of ${items.length} (${paymentsToAutomatch.length} matches))`);
            }
        }
        new LoaderEvent(false);
        if (paymentsToAutomatch.length === 0) return;

        // const promptToAcceptAutoMatch = new Prompt(
        //     'general.confirm',
        //     `Go ahead and match ${paymentsToAutomatch.length} payments?` as TranslationKey
        // );
        // await promptToAcceptAutoMatch.show();
        // if (promptToAcceptAutoMatch.cancelled) return;
        // new LoaderEvent(true);
        // for (const item of paymentsToAutomatch) {
        //     await this.match(item, true);
        // }
        new LoaderEvent(false);
    }

    static async createPayment(args: {
        matchedItem: MatchPayment;

        template?: Partial<Transaction> | undefined;
        paymentAccount: PaymentAccount;
        dontConfirm?: boolean;
        matcher?: TMatcher;
    }) {
        const { matchedItem, template, paymentAccount } = args;

        if (!matchedItem.customer) {
            if (template?.customerId) {
                matchedItem.customer = Data.get<Customer>(template.customerId);
            }
            if (!matchedItem.customer) {
                matchedItem.customer = await CustomerService.selectOrCreateCustomer(
                    {
                        ref: matchedItem.providerPayment.customerId || '',
                        name: matchedItem.providerPayment.description || '',
                    },
                    matchedItem.customer
                );
            }
        }

        if (!matchedItem.customer) return;

        if (!args.dontConfirm) {
            let paymentDialog;

            if (matchedItem.providerPayment.amount > 0) {
                paymentDialog = await CustomerRefundDialog.createForProviderPayment(
                    matchedItem.customer,
                    matchedItem.provider,
                    matchedItem.providerPayment,
                    paymentAccount
                );
            } else {
                paymentDialog = await CustomerCreditDialog.createForProviderPayment(
                    matchedItem.customer,
                    matchedItem.provider,
                    matchedItem.providerPayment,
                    matchedItem.invoice,
                    paymentAccount
                );
            }

            if (!paymentDialog) return;

            const payment = await paymentDialog.show();
            if (paymentDialog.cancelled || !payment) return;
            matchedItem.match = Data.get(payment._id);
        } else {
            const id = matchedItem.providerPayment._id;
            const customerId = matchedItem.customer._id;
            const { amount, date } = matchedItem.providerPayment;
            const description = matchedItem.providerPayment.description || 'Bank transfer';
            const accountId = args.paymentAccount?._id;
            const invoiceIds = matchedItem.invoice?._id ? [matchedItem.invoice._id] : undefined;
            if (matchedItem.providerPayment.amount > 0) {
                const refund = RefundTransaction.create({
                    id,
                    date,
                    amount,
                    customerId,
                    description,
                    accountId,
                    provider: matchedItem.provider,
                    invoiceIds,
                    type: 'refund.bank-transfer',
                });
                refund.statementDescription = matchedItem.providerPayment.description;
                await Data.put(refund, true, 'lazy');

                matchedItem.match = Data.get(refund._id);
            } else {
                const isTip = matchedItem.allocateInvoice == 'tip';
                const payment = PaymentTransaction.create({
                    id,
                    date,
                    amount,
                    customerId,
                    description: description + (isTip ? ' (tip)' : ''),
                    type: isTip ? 'payment.tip.bank-transfer' : 'payment.bank-transfer',
                    accountId,
                    provider: matchedItem.provider,
                    invoiceIds,
                });
                payment.statementDescription = matchedItem.providerPayment.description;

                if (!matchedItem.allocateInvoice || matchedItem.allocateInvoice === 'auto') {
                    await Data.put(payment, true, 'lazy');
                    matchedItem.match = Data.get(payment._id);
                    await InvoiceService.allocateBalanceToInvoices(customerId);
                } else if (matchedItem.allocateInvoice === 'manual') {
                    if (await ChargeCustomerService.manualAllocation(payment)) {
                        await Data.put(payment, true, 'lazy');
                        matchedItem.match = Data.get(payment._id);
                    }
                } else if (matchedItem.allocateInvoice === 'tip') {
                    const updates = ChargeCustomerService.createTipAdjustmentFromPayment(payment, [payment]);
                    await Data.put(updates, true, 'lazy');
                    matchedItem.match = Data.get(payment._id);
                }
            }
        }
        if (matchedItem.customer._id) InvoiceService.allocateBalanceToInvoices(matchedItem.customer._id);
        if (!matchedItem.match) return;

        if (matchedItem.match?.paymentDetails?.invoiceIds?.[0]) {
            matchedItem.invoice = Data.get(matchedItem.match?.paymentDetails?.invoiceIds?.[0]);
        }

        if (matchedItem.match?.customerId) matchedItem.customer = Data.get(matchedItem.match?.customerId);

        return await this.match(matchedItem, paymentAccount?._id, false);
    }

    static async createExpenseFromMatchedItem(args: {
        matchedItem: MatchPayment;
        template?: Partial<Expense>;
        dontConfirm?: boolean;
        paymentAccount: PaymentAccount;
        matcher?: TMatcher;
    }) {
        let expense: Expense | ExpenseRefund;

        if (args.matchedItem.providerPayment.amount < 0) {
            expense = new ExpenseRefund(
                Math.abs(args.matchedItem.providerPayment.amount),
                args.template?.description || args.matchedItem.providerPayment.description || '',
                undefined,
                undefined,
                args.matchedItem.providerPayment.date
            );
        } else {
            expense = new Expense(
                'expense.imported',
                Math.abs(args.matchedItem.providerPayment.amount),
                args.template?.description || args.matchedItem.providerPayment.description || '',
                undefined,
                undefined,
                args.matchedItem.providerPayment.date
            );
        }
        expense.statementDescription = args.matchedItem.providerPayment.description;
        expense.paymentDate = args.matchedItem.providerPayment.date;
        expense.paymentAccountId = args.paymentAccount?._id;

        if (args.template) {
            expense.taxEnabled = args.template.taxEnabled;
            expense.taxRate = args.template.taxRate;
            expense.accountId = args.template.accountId;
            expense.category = args.template.category;
            expense.supplierId = args.template.supplierId;
        }

        if (!args.dontConfirm) {
            const dialog = new ExpenseFormDialog(expense, args.paymentAccount);
            await dialog.show();
            if (dialog.cancelled) return;
        } else await Data.put(expense, true, 'lazy');
        args.matchedItem.match = Data.get<Expense>(expense._id);
        return await this.match(args.matchedItem, args.paymentAccount._id, args.dontConfirm || false);
    }

    static async transferIn(req: {
        matchedItem: MatchPayment;
        paymentAccount: PaymentAccount;
        template?: Partial<TransferTransaction>;
        matcher?: TMatcher;
        dontConfirm: boolean;
    }) {
        const transfer = await AccountsLedgerService.createTransferIn(
            req.paymentAccount,
            req.matchedItem.providerPayment.date,
            req.matchedItem.providerPayment.amount,
            req.matchedItem.paymentAccountId || req.template?.targetAccountId
        );

        if (!transfer) return;

        await Data.put([transfer.from, transfer.to], true, 'lazy');
        req.matchedItem.match = Data.get(transfer.to._id);
        if (!req.matchedItem.match) return;

        return await this.match(req.matchedItem, req.paymentAccount._id, req.dontConfirm);
    }
    static async transferOut(req: {
        matchedItem: MatchPayment;
        paymentAccount: PaymentAccount;
        template?: Partial<TransferTransaction>;
        matcher?: TMatcher;
        dontConfirm: boolean;
    }) {
        const transfer = await AccountsLedgerService.createTransferOut(
            req.paymentAccount,
            req.matchedItem.providerPayment.date,
            req.matchedItem.providerPayment.amount,
            req.matchedItem.paymentAccountId || req.template?.targetAccountId
        );

        if (!transfer) return;

        await Data.put([transfer.from, transfer.to], true, 'lazy');
        req.matchedItem.match = Data.get(transfer.from._id);

        return await this.match(req.matchedItem, req.paymentAccount._id, req.dontConfirm);
    }

    static generateMatchHashOnDateAndAmount(transaction: Transaction) {
        return transaction.date.substring(0, 10) + transaction.amount;
    }

    static generateEntityMap(provider: TransactionProvider, paymentAccountId: string, from?: Date, to?: Date) {
        const provSettings = ConnectedServicesService.getProviderSettings(provider);

        const matched: Record<string, Transaction> = {};
        const matchedInPeriod: Array<Transaction> = [];
        const ignored: Record<string, 'ignored'> = provSettings?.entityMap;
        const unmatched: Record<string, Array<Transaction>> = {};
        const duplicate: Record<string, Array<Transaction>> = {};

        const payments = this.getPayments(paymentAccountId);

        for (const payment of payments) {
            if (payment.transactionType === TransactionType.Unreconciled) continue;
            if (payment.voidedId) continue;
            if (!payment.externalIds) continue;
            if (!payment.externalIds[provider]) continue;

            // if (!payment.externalIds[provider]) {
            //     const hash = this.generateMatchHashOnDateAndAmount(payment);
            //     if (!unmatched[hash]) unmatched[hash] = [];
            //     unmatched[hash].push(payment);
            //     continue;
            // }

            if (matched[payment.externalIds[provider] as string]) {
                if (!duplicate[payment.externalIds[provider] as string]) duplicate[payment.externalIds[provider] as string] = [];
                duplicate[payment.externalIds[provider] as string].push(payment);
            }
            matched[payment.externalIds[provider] as string] = payment;
            if (from && moment(payment.date).isBefore(moment(from).endOf('day'))) continue;
            if (to && moment(payment.date).isAfter(moment(to).endOf('day'))) continue;
            matchedInPeriod.push(payment);
        }
        return { matched, ignored, unmatched, matchedInPeriod };
    }

    static async loadMatchesForProviderPayments(req: {
        providerPayments: Array<Transaction>;
        provider: TransactionProvider;
        paymentAccountId: string;
        from?: Date;
        to?: Date;
    }): Promise<Array<MatchPayment>> {
        const matchedItems: Array<MatchPayment> = [];

        const { provider, providerPayments } = req;

        const entityMap = MatchPaymentService.generateEntityMap(provider, req.paymentAccountId, req.from, req.to);
        // const duplicates: Array<Array<Transaction>> = [];
        // // Check the date range for any duplicates
        // for (const accountPayment of entityMap.matchedInPeriod) {
        //     // Is this a dupe of a previous payment?
        //     const otherMatchedInPeriod = entityMap.matchedInPeriod.filter(p => p._id !== accountPayment._id);
        //     for (const otherPayment of otherMatchedInPeriod) {
        //         if (MatchPaymentService.isPreviousMatch(accountPayment, otherPayment, provider) === 1) {
        //             duplicates.push([accountPayment, otherPayment]);
        //         }
        //     }
        // }

        // if (duplicates.length > 0) {
        //     new LoaderEvent(false);
        //     const alert = new Prompt(
        //         'Duplicate payments' as TranslationKey,
        //         (duplicates.length +
        //             ' payments have been matched to multiple transactions. To resolve this we recommend you unmatch the oldest transaction.') as TranslationKey,
        //         { cancelLabel: 'actions.skip', okLabel: 'Unmatch Oldest' as TranslationKey }
        //     );

        //     if (await alert.show()) {
        //         for (const dp of duplicates) {
        //             if (duplicates.length === 0) break;
        //             const oldest = dp.sort((a, b) => sortByCreatedDateAsc(a, b))[0];
        //             const newest = dp[1];

        //             if (oldest) {
        //                 Logger.info('Unmatching oldest payment', { oldest, newest });
        //             }
        //             delete entityMap.matched[oldest._id];
        //             entityMap.matched[newest._id] = newest;
        //         }
        //     }
        // }

        for (let i = 0; i < providerPayments.length; i++) {
            const providerPayment = req.providerPayments[i];
            const matched = await MatchPaymentService.findMatchesForProviderPayment({
                providerPayment,
                entityMap,
                provider,
            });
            if (matched.match) matched.loaded = true;
            matchedItems.push(matched);
        }

        //Do we have some previously matched payments that are not in the current provider payments
        const matchedButNotMatchedAnymore = [];

        entityMap.matchedInPeriod.forEach(p => {
            if (matchedItems.find(m => m.match && p.externalIds?.[provider] === m.match.externalIds?.[provider])) return;
            matchedButNotMatchedAnymore.push(p);
        });

        if (matchedButNotMatchedAnymore.length > 0) {
            const payments = MatchPaymentService.getPayments(req.paymentAccountId);

            await MatchPaymentService.autoMatch(
                matchedItems.filter(x => x.status === 'unmatched'),
                payments
            );
        }

        return matchedItems.sort((a, b) => sortByDateAsc(a.providerPayment, b.providerPayment));
    }

    static async match(matchItem: MatchPayment, paymentAccountId: string, dontConfirm: boolean) {
        const { match, providerPayment } = matchItem;

        if (!match) return;

        const provider = matchItem.provider;
        if (!provider) throw 'No provider specified';

        const externalId = providerPayment.externalIds?.[provider] || providerPayment._id;
        if (!externalId) return;

        if (match.transactionType === TransactionType.Transfer) {
            // WTF? Always update the transaction date for transfers to be the same as the provider date.
            // and the amount if they are different (could be currency conversion)
            match.date = providerPayment.date;
            if (match.amount !== providerPayment.amount) {
                match.amount = providerPayment.amount;
            }
            match.currency = providerPayment.currency;
        }
        if (!match.externalIds) match.externalIds = {};

        match.externalIds[provider] = externalId;
        match.statementDescription = providerPayment.description;

        // if we are matching a payment that was not in the account then lets move it to the account
        if (!match.paymentDetails) match.paymentDetails = {};
        match.paymentDetails.paymentAccountId = paymentAccountId;

        await Data.put(match, true, 'lazy');

        if (matchItem.status === 'ignored') {
            await this.unignore(matchItem);
        }
        matchItem.status = 'matched';
        if (!dontConfirm) {
            MatcherService.extractAndSaveMatcher(matchItem);
        }

        return matchItem;
    }

    static async unmatch(matchedItem: MatchPayment) {
        Logger.info('Matched item:', matchedItem);

        const provider = matchedItem.provider;

        const providerPayment = matchedItem.providerPayment;
        if (!providerPayment) return;

        const externalId = providerPayment.externalIds?.[provider] || matchedItem.providerPayment._id;

        const match = matchedItem.match;
        if (match) {
            const updates = new Array<Transaction>();
            delete match.externalIds?.[provider];
            if (match.statementDescription) match._deleted = true;
            updates.push(match);

            const voided = Data.firstOrDefault<Transaction>('transactions', t => t.voidedId === match._id);
            if (voided) {
                voided._deleted = true;
                updates.push(voided);
            }

            await Data.put(updates);

            const providerData = await ConnectedServicesService.getProviderSettings(matchedItem.provider);
            delete providerData.entityMap[externalId];
            await ConnectedServicesService.saveProviderConfig(matchedItem.provider, providerData);
        }

        matchedItem.match = undefined;
        matchedItem.customer = undefined;
        matchedItem.invoice = undefined;
        matchedItem.loaded = false;
        matchedItem.status = 'unmatched';
    }

    static async ignore(matchedItem: MatchPayment) {
        const provider = matchedItem.provider;

        const providerPayment = matchedItem.providerPayment;
        if (!providerPayment) return;

        const externalId = providerPayment.externalIds?.[provider] || matchedItem.providerPayment._id;

        if (externalId) {
            const providerData = await ConnectedServicesService.getProviderSettings(matchedItem.provider);
            providerData.entityMap[externalId] = 'ignored';
            await ConnectedServicesService.saveProviderConfig(matchedItem.provider, providerData);
        }

        matchedItem.match = undefined;
        matchedItem.customer = undefined;
        matchedItem.invoice = undefined;

        matchedItem.status = 'ignored';
    }

    static async unignore(matchedItem: MatchPayment) {
        const provider = matchedItem.provider;

        const providerPayment = matchedItem.providerPayment;
        if (!providerPayment) return;

        const externalId = providerPayment.externalIds?.[provider] || matchedItem.providerPayment._id;

        if (externalId) {
            const providerData = await ConnectedServicesService.getProviderSettings(matchedItem.provider);
            if (providerData.entityMap[externalId]) {
                delete providerData.entityMap[externalId];
                await ConnectedServicesService.saveProviderConfig(matchedItem.provider, providerData);
            }
        }
    }

    private static async findMatchesForProviderPayment({
        entityMap,
        provider,
        providerPayment,
    }: {
        providerPayment: Transaction;
        provider: TransactionProvider;
        entityMap: IMatchMap;
    }) {
        const matchPayment = new MatchPayment(providerPayment, provider);
        const externalId = providerPayment.externalIds?.[provider] || providerPayment._id;
        if (!externalId) return matchPayment;

        if (entityMap.ignored[externalId]) {
            matchPayment.status = 'ignored';
            return matchPayment;
        }

        if (entityMap.matched[externalId]) {
            matchPayment.status = 'matched';
            matchPayment.match = entityMap.matched[externalId];
            return matchPayment;
        }

        if (providerPayment.customerId) {
            const cust = Data.get<Customer>(providerPayment.customerId);
            if (cust) {
                matchPayment.customer = cust;
            }
        }

        const invoiceId = providerPayment.paymentDetails?.invoiceIds?.[0];
        if (invoiceId) {
            const invoice = Data.get<Transaction>(invoiceId);
            if (invoice) {
                matchPayment.invoice = invoice;
                if (!matchPayment.customer && invoice.customerId) matchPayment.customer = Data.get(invoice.customerId);
            }
        }

        if (provider === 'gocardless') {
            matchPayment.type = 'Customer Payment';
        }

        return matchPayment;
    }

    checkForIssuesWithPreviousMatches() {
        // Get previous matches for this business
    }

    static findDuplicates(transactions: Array<Transaction>, includeVoids = false) {
        try {
            new LoaderEvent(true, true, 'payments.finding-duplicate-payments');
            const keyedDupes: {
                [text: string]: { [transactionId: string]: Transaction };
            } = {};

            const hideCancelled = ApplicationState.getSetting('global.hide-cancelled-transactions', false);

            for (const t of transactions) {
                if (!includeVoids && hideCancelled && t.voidedId && Data.get(t.voidedId)) continue;
                const otherMatches = transactions.filter(
                    x =>
                        x._id !== t._id &&
                        MatchPaymentService.isDuplicatedPayment(t as PaymentTransaction | Expense, x as PaymentTransaction | Expense)
                );

                if (otherMatches.length > 0) {
                    const key = t.date.substring(0, 10) + '(' + t.description + ')';
                    if (!keyedDupes[key]) keyedDupes[key] = {};
                    keyedDupes[key][t._id] = t;
                    for (const om of otherMatches) {
                        keyedDupes[key][om._id] = om;
                    }
                }
            }

            return keyedDupes;
        } finally {
            new LoaderEvent(false);
        }
    }
}
