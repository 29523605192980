import type { StoredObject } from '@nexdynamic/squeegee-common';
import { randomInteger } from '@nexdynamic/squeegee-common';
import { BGLoaderEvent } from '../Events/BGLoaderEvent';
import { Logger } from '../Logger';
import { Stopwatch } from '../Stopwatch';
import { animate } from '../Utilities';
import { DataStoreManager } from './DataStoreManager';
import { TaskQueue } from './TaskQueue';

export class SQLiteDataStoreManager extends DataStoreManager {
    private _db: SQLitePlugin.Database;

    private _resumeTimeout: any;
    private _writeQueue = new TaskQueue(1);
    public async initialise(): Promise<void> {
        Logger.info('Initialising SQLiteDataStoreManager');

        const timer = new Stopwatch('Loading all data into memory.');
        await this._initDB();
        await this._getAllFromDataStore();
        timer.lap('completed loading of data');
        document.addEventListener(
            'resume',
            () => {
                clearTimeout(this._resumeTimeout);
                this._resumeTimeout = setTimeout(() => {
                    Logger.info('Resuming data writes due to resume event');
                    this._writeQueue.resume();
                }, 500);
            },
            false
        );
        document.addEventListener(
            'pause',
            () => {
                clearTimeout(this._resumeTimeout);
                Logger.info('Pausing data writes due to pause event');
                this._writeQueue.pause();
            },
            false
        );
    }

    private _initDB(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._db = window.sqlitePlugin.openDatabase(
                {
                    name: this.name,
                    location: 'default',
                },
                db => {
                    db.transaction(
                        tx => {
                            tx.executeSql('CREATE TABLE IF NOT EXISTS dataTable (id TEXT, jsonData TEXT)');
                        },
                        error => {
                            Logger.error('CREATE TABLE transaction error: ' + error.message);
                            return reject(error);
                        },
                        () => {
                            return resolve();
                        }
                    );
                },
                error => {
                    Logger.error('Unable to open sql database', { error });
                    reject(error);
                }
            );
        });
    }

    private async _getAllFromDataStore(): Promise<void> {
        const recordCount = await this.getRecordCount();
        if (!recordCount) return;
        let returned: number | undefined;
        let offset = 0;
        do {
            returned = await this._getAllFromDataStoreWithOffset(offset);
            offset += returned;
            await BGLoaderEvent.emit(true, `Loading ${offset} of ${recordCount} records...`, (offset / recordCount) * 100);
            await animate();
        } while (returned !== 0);

        let waiter = 5;
        while (waiter--) {
            await BGLoaderEvent.emit(true, `Processing of all loaded records...`, ((5 - waiter) / 5) * 100);
            await animate(Math.round(recordCount / 250));
        }
    }

    private _getAllFromDataStoreWithOffset(offset: number): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            const limit = randomInteger(1950, 2050);

            this._db.executeSql(
                `SELECT jsonData FROM dataTable LIMIT ${limit} OFFSET ?;`,
                [offset],
                results => {
                    let batch = '[';
                    for (let i = 0; i < results.rows.length; i++) {
                        batch += (batch.length === 1 ? '' : ',') + results.rows.item(i).jsonData;
                        if (i % 15 === 0) {
                            batch += ']';
                            const batchItems: Array<StoredObject> = JSON.parse(batch);
                            for (const item of batchItems) this.storeInCollectionData(item._id, item);
                            batch = '[';
                        }
                    }
                    if (batch.length > 0) {
                        batch += ']';
                        const batchItems: Array<StoredObject> = JSON.parse(batch);
                        for (const item of batchItems) this.storeInCollectionData(item._id, item);
                    }
                    resolve(results.rows.length);
                },
                error => reject(error)
            );
        });
    }

    public getRecordCount(): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            this._db.executeSql(
                'SELECT COUNT(*) as recordCount FROM dataTable',
                [],
                rs => {
                    resolve(rs.rows.item(0).recordCount);
                },
                error => reject(error)
            );
        });
    }

    public async getFromDataStore(id: string): Promise<StoredObject | undefined> {
        return new Promise((resolve, reject) => {
            this._db.executeSql(
                'SELECT jsonData WHERE id = ? FROM dataTable',
                [id],
                rs => {
                    const dataAsString = rs.rows.item(0) as string;
                    if (!dataAsString || dataAsString.length === 0) return resolve(undefined);
                    const dataAsObject = JSON.parse(dataAsString);
                    resolve(dataAsObject);
                },
                error => reject(error)
            );
        });
    }

    public async writeOneToDataStore(storedObject: StoredObject): Promise<void> {
        this._writeQueue.enqueue(async () => {
            const query = 'INSERT INTO dataTable (id, jsonData) VALUES (?,?)';
            await new Promise<void>((resolve, reject) => {
                this._db.executeSql(
                    query,
                    [storedObject._id, JSON.stringify(storedObject)],
                    () => resolve(),
                    error => reject(error)
                );
            });
        });
    }

    public async writeAllToDataStore(storedObjects: readonly StoredObject[]): Promise<void> {
        this._writeQueue.enqueue(async () => {
            const query = 'INSERT INTO dataTable (id, jsonData) VALUES (?,?)';
            await new Promise<void>((resolve, reject) => {
                const statements: Array<[string, any[]]> = storedObjects.map(so => [query, [so._id, JSON.stringify(so)]]);
                this._db.sqlBatch(
                    statements,
                    () => resolve(),
                    error => reject(error)
                );
            });
        });
    }

    public async deleteAllFromDataStore(_ids: readonly string[]): Promise<boolean> {
        for (const id of _ids) this.deleteFromCollectionData(id);
        this._writeQueue.enqueue(async () => {
            await new Promise<boolean>(resolve => {
                const query = 'DELETE FROM dataTable WHERE id = ?';
                const statements: Array<[string, any[]]> = _ids.map(id => [query, [id]]);

                this._db.sqlBatch(
                    statements,
                    () => resolve(true),
                    error => {
                        Logger.error('Error cleaning up records', { error });
                        BGLoaderEvent.emit(false, `Error cleaning up records`, 0);
                        resolve(false);
                    }
                );
            });
        });
        return true; //TODO: Refactor need to return a boolean
    }

    public removeAllDataStoresFromDisk(): Promise<void> {
        return new Promise((resolve, reject) => {
            this._db.transaction(
                tx => {
                    tx.executeSql('DROP TABLE IF EXISTS dataTable');
                },
                error => {
                    reject(error);
                    Logger.error('DROP TABLE transaction error: ' + error.message);
                },
                () => {
                    this._db.close();
                    return resolve();
                }
            );
        });
    }
}
