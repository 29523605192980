import type { AccountUser, CommonApplicationState, CustomerNotificationMethodTypes, TranslationKey } from '@nexdynamic/squeegee-common';
import { ProductLevel } from '@nexdynamic/squeegee-common';
import { AccountActions } from '../Account/AccountActions';
import { AccountBillingService } from '../Account/AccountBillingService';
import { presentSmsSettings } from '../Account/utils/presentSmsSettings';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { SqueegeeImporter } from '../Data/SqueegeeImporter';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { FileUploadDialog } from '../Dialogs/FileUploadDialog';
import { FrequencyPickerDialog } from '../Dialogs/FrequencyPicker/FrequencyPickerDialog';
import { Prompt } from '../Dialogs/Prompt';
import { LoaderEvent } from '../Events/LoaderEvent';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { SettingsDialog } from '../Settings/SettingsDialog';
import { Squeegee } from '../Squeegee';
import { SelectUserOrTeamDialog } from '../Users/SelectUserOrTeamDialog';
import { Utilities } from '../Utilities';

export class QuickStart {
    private _additionalUsersCount = '0';
    private _originalUserCount = '0';

    protected initialising = true;

    protected get isConnected() {
        return Api.isConnected;
    }

    isAppleDevice = GlobalFlags.isAppleMobileDevice;

    protected get emailVerified() {
        return !RethinkDbAuthClient.session?.unverified;
    }

    protected gotToAccount() {
        ApplicationState.navigateTo('account');
    }

    protected defaultTaxRateLabel = ApplicationState.localise('quickstart.default-tax-rate');
    protected taxLabel = ApplicationState.localise('quickstart.tax-label');
    protected currentEndpoint = Api.currentHostAndScheme;
    protected get account() {
        return ApplicationState.account;
    }
    protected async support() {
        await ApplicationState.getSupport('Squeegee App Feedback', false, '', 'support.support-request');
    }

    public async selectBusinessLogo() {
        return await ApplicationState.setBusinessLogo();
    }

    public async checkEmailVerificationStatus() {
        new LoaderEvent(true, true, 'loader.checking-email-verification-status');
        await RethinkDbAuthClient.validateSession();
        setTimeout(() => new LoaderEvent(false), 1000);
    }

    public async attached() {
        const subscription = ApplicationState.hasCachedSubscription
            ? ApplicationState.subscription
            : await ApplicationState.updateSubscription();

        const notSubscribed =
            (subscription && subscription.product && subscription.product.productLevel === ProductLevel.NotSubscribed) || false;

        if (notSubscribed) {
            const plan = (await AccountBillingService.getSelectablePlans())?.find(p => p.planLevel === 'ultimate');
            if (plan) {
                const planId = plan?.periods.find(p => p.interval === 'month')?.planId;
                if (planId) {
                    const { success } = await AccountActions.subscribe(planId, 1, false, false, false, true);

                    if (success) {
                        await Squeegee.postCheckAndConfirmDetails();
                    }
                }
            }
        }

        this._additionalUsersCount = (ApplicationState.subscription.users || 0).toString();
        this._originalUserCount = this._additionalUsersCount;
        this.initialising = false;
    }
    public detached() {
        Logger.info('detached the quick start.');
    }
    protected async save() {
        if (this._additionalUsersCount !== this._originalUserCount) {
            if (ApplicationState.subscription.plan?.id && this._additionalUsersCount.length > 0) {
                const result = await AccountActions.subscribe(
                    ApplicationState.subscription.plan?.id,
                    parseInt(this._additionalUsersCount),
                    true,
                    false,
                    false
                );
                if (!result.success) {
                    return new NotifyUserMessage('general.error');
                }

                this._originalUserCount = this._additionalUsersCount;
            }
        }
        await ApplicationState.save();
        new NotifyUserMessage('quickstart.saved');
    }

    protected sendConfirmEmail() {
        RethinkDbAuthClient.resendConfirmationEmail(this.account.email);
        new NotifyUserMessage('notifications.sign-up-confirm-email-sent', { email: this.account.email });
    }

    protected async setBusinessAddress() {
        await ApplicationState.setBusinessAddress();
    }

    protected get isMobile() {
        return GlobalFlags.isMobile;
    }

    protected get defaultInvoiceGenerationMethod() {
        return this.account.invoiceSettings.defaultInvoiceGenerationMethod !== 'manual';
    }

    protected set defaultInvoiceGenerationMethod(yes: boolean) {
        this.account.invoiceSettings.defaultInvoiceGenerationMethod = yes ? 'auto' : 'manual';
        this.save();
    }

    protected get defaultAutoInvoiceNotification() {
        return this.account.invoiceSettings.defaultAutoInvoiceNotification === 'auto';
    }

    protected set defaultAutoInvoiceNotification(yes: boolean) {
        this.account.invoiceSettings.defaultAutoInvoiceNotification = yes ? 'auto' : 'manual';
        this.save();
    }

    protected get taxEnabled() {
        return this.account.invoiceSettings.taxEnabled;
    }

    protected set taxEnabled(yes: boolean) {
        this.account.invoiceSettings.taxEnabled = yes;
        if (yes) {
            //todo - add default tax rates from a config file for all countries.
            if (ApplicationState.account.language.indexOf('GB')) {
                this.account.invoiceSettings.taxRate = 20;
                this.account.invoiceSettings.taxLabel = 'VAT';
            }
        }
        this.save();
    }

    protected get priceIncludesTax() {
        return this.account.invoiceSettings.priceIncludesTax;
    }

    protected set priceIncludesTax(yes: boolean) {
        this.account.invoiceSettings.priceIncludesTax = yes;
        this.save();
    }

    public async openSettingsDialog(event: Event) {
        event.stopPropagation();
        try {
            // IOS Hack for the menu events bubbling.
            if (GlobalFlags.isMobile) setTimeout(() => !document.querySelector || document.querySelector('#menuDrawer.is-visible'), 0);

            const dialog = new SettingsDialog();
            await dialog.show(DialogAnimation.SLIDE);
        } catch (error) {
            Logger.error(`Error during openSettingsDialog in menu drawer`, error);
        }
    }

    public notificationOptions: Array<{ text: TranslationKey; value: string }> = [
        { text: 'general.none', value: '' },
        { text: 'customers.notification-email-label', value: 'email' },
        { text: 'customers.notification-phone-label', value: 'sms' },
        { text: 'customers.notification-alternative-phone-label', value: 'sms2' },
        { text: 'customers.notification-email-and-phone-label', value: 'email-and-sms' },
        { text: 'customers.notification-email-and-phone2-label', value: 'email-and-sms2' },
    ];

    protected get defaultNotificationMethod() {
        return this.account.defaultNotificationMethod as CustomerNotificationMethodTypes;
    }

    protected set defaultNotificationMethod(value: CustomerNotificationMethodTypes) {
        this.account.defaultNotificationMethod = value;
        this.save();
    }

    protected get additionalUsers() {
        return this._additionalUsersCount;
    }
    protected set additionalUsers(value: string) {
        this._additionalUsersCount = value;
    }

    public setSmsSettings() {
        presentSmsSettings();
    }

    public async importCSV() {
        const dialog = new FileUploadDialog(['.csv'], 'uploads.csv-import-file');
        const files = await dialog.show();
        if (files && files.length) {
            const file = files[0];
            try {
                const deleteExisting = new Prompt('prompts.delete-existing-data-title', 'prompts.delete-existing-data-text', {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.no',
                });
                const deleteExistingData = await deleteExisting.show();

                new LoaderEvent(true);

                const applicationState: CommonApplicationState = Utilities.copyObject(ApplicationState.instance);

                let importedOutput: { warnings?: Array<string>; error: string; success: boolean } = { error: '', success: false };

                const fileContent = await Utilities.readFileAsString(file);
                importedOutput = await SqueegeeImporter.import(
                    fileContent,
                    file.name.endsWith('json') ? 'json' : 'csv',
                    deleteExistingData
                );

                if (deleteExistingData && importedOutput.success) {
                    await Data.put(applicationState);
                }

                let html = `${ApplicationState.localise('prompts.import-complete-text')}`;

                if (!importedOutput.success) {
                    html += `<br>${ApplicationState.localise('prompts.import-complete-error-text')}<br>${importedOutput.error}`;
                }

                if (importedOutput.warnings && importedOutput.warnings.length) {
                    html += `<br>${ApplicationState.localise('prompts.import-complete-warnings-text')}<br>${importedOutput.warnings.join(
                        '<br>'
                    )}`;
                }

                new LoaderEvent(false);
                new Prompt(importedOutput.success ? 'prompts.success-title' : 'prompts.error-title', <any>html, { cancelLabel: '' }).show();
            } catch (error) {
                new LoaderEvent(false);
                new Prompt('prompts.error-title', 'prompts.import-error-text', { cancelLabel: '' }).show();
                Logger.error('Error in importCsv() on SettingsDialog', { error });
            }
        }
    }

    public navigateToCustomers() {
        ApplicationState.navigateTo('customers');
    }

    protected setDefaultJobFrequency = async () => {
        const dialog = new FrequencyPickerDialog(
            ApplicationState.defaultJobFrequency,
            true,
            true,
            false,
            'frequency',
            undefined,
            undefined,
            false
        );
        const frequencyResult = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            try {
                ApplicationState.defaultJobFrequency = frequencyResult;
                ApplicationState.save();
            } catch (error) {
                new NotifyUserMessage('general.could-not-save');
            }

            new NotifyUserMessage('general.saved-successfully');
        }
    };

    protected async setDefaultAssignee() {
        const dialog = new SelectUserOrTeamDialog(undefined, undefined, undefined, undefined, undefined, true);
        const teamOrUser = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            try {
                ApplicationState.account.defaultAssignee =
                    !teamOrUser || !teamOrUser._id
                        ? <any>null
                        : teamOrUser.resourceType === 'team'
                        ? teamOrUser._id
                        : (teamOrUser as AccountUser).email;
                ApplicationState.save();
            } catch (error) {
                new NotifyUserMessage('general.could-not-save');
            }

            new NotifyUserMessage('general.saved-successfully');
        }
    }
}
