import type { Customer, Notification, NotificationType, TranslationKey } from '@nexdynamic/squeegee-common';
import { replaceMessageTokensWithModelValues, uuid } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { EmailTemplateService } from '../EmailTemplates/EmailTemplateService';
import { LoaderEvent } from '../Events/LoaderEvent';
import { ViewResizeEvent } from '../Events/ViewResizeEvent';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { SendNotificationService } from '../Notifications/SendNotificationService';
import type { CannedResponse } from '../ReactUI/canned-responses/CannedResponse';
import CannedResponsesDialog from '../ReactUI/canned-responses/CannedResponsesDialog';
import { checkHasEnoughCreditsToPublish } from '../ReactUI/send/features/campaigns/CampaignService';
import { MessageTemplates } from '../Settings/MessageTemplates';
import { Utilities, animate } from '../Utilities';
import { t } from '../t';
import { AureliaReactComponentDialog } from './AureliaReactComponentDialog';
import { CustomDialogBase } from './CustomDialogBase';
import type { DialogSettingsViewport } from './DialogSettings';
import { Prompt } from './Prompt';
import './SendMessageToCustomer.scss';
import type { ITinyMceTool } from './TinyMcePlugin';
import { TinyMcePlugin } from './TinyMcePlugin';
import { getSecondsTimestampForSchedule } from './getDateAndTimeForSchedule';

type NotificationMethod = { address: string; type: NotificationType };
export class SendMessageToCustomer extends CustomDialogBase<boolean> {
    protected account = ApplicationState.account;
    protected notificationMethods: Array<NotificationMethod> = [];
    public notificationMethod: NotificationMethod | undefined;
    protected emailMessage: string;
    protected smsMessage: string;
    protected editorTools?: Array<ITinyMceTool>;
    protected tinyMcePluginInstance: TinyMcePlugin;

    protected tinyMcePluginId: string = uuid();
    public notifications: { [customerId: string]: Notification };
    public sent = { email: 0, sms: 0 };
    protected smsCannedResponseButtonTitle = t('settings.canned-responses-insert-tooltip');
    constructor(
        protected customer: Customer | Array<Customer>,
        protected messages: { sms: string; email: string; emailIsHtml: boolean; subject?: string } | undefined,
        settings: DialogSettingsViewport = {
            cssClass: 'message-customer-dialog',
            coverViewport: true,
            smallerOnDesktop: false,
            okLabel: SendMessageToCustomer.canNotify(customer) ? 'general.send' : '',
            altLabel: ApplicationState.features.scheduledMessaging ? 'general.schedule' : undefined,
        },
        titleOverride: TranslationKey = 'dialogs.notify-customer-title',
        editorReadonly = false,
        private models?: Record<string, { [name: string]: string | number | boolean }>
    ) {
        super('sendMessageToCustomerDialog', './SendMessageToCustomer.html', titleOverride, settings);

        this.tinyMcePluginInstance = new TinyMcePlugin(this.tinyMcePluginId, this.editorTools, true, editorReadonly);

        this.emailMessage = this.getDefaultEmailMessage();
        this.smsMessage = this.getDefaultSMSMessage();

        const smsApi = ApplicationState.account.smsAPI;
        const smsEnabled = smsApi === 'squeegee-sms' || smsApi === 'squeegee-sms-two-way' || smsApi === 'squeegee-sms-two-way-private';
        const onlyCustomer = !Array.isArray(customer) ? customer : customer.length === 1 ? customer[0] : undefined;
        if (onlyCustomer) customer = onlyCustomer;
        if (onlyCustomer) {
            const email = onlyCustomer.email?.trim();
            if (email) {
                const notificationMethod: NotificationMethod = { address: email, type: 'Email' };
                this.notificationMethods.push(notificationMethod);
                this.notificationMethod = notificationMethod;
            }

            if (smsEnabled) {
                const telephoneNumber = onlyCustomer.telephoneNumber?.trim();
                if (telephoneNumber) {
                    const notificationMethod: NotificationMethod = { address: telephoneNumber, type: 'SMS' };
                    this.notificationMethods.push(notificationMethod);
                    if (!this.notificationMethod || onlyCustomer.defaultNotificationMethod === 'sms')
                        this.notificationMethod = notificationMethod;
                }

                const telephoneNumberOther = onlyCustomer.telephoneNumberOther?.trim();
                if (telephoneNumberOther) {
                    const notificationMethod: NotificationMethod = { address: telephoneNumberOther, type: 'SMS' };
                    this.notificationMethods.push(notificationMethod);
                    if (!this.notificationMethod || onlyCustomer.defaultNotificationMethod === 'sms2')
                        this.notificationMethod = notificationMethod;
                }
            }
        } else {
            this.notificationMethods.push({ address: 'Email', type: 'Email' });
            if (smsEnabled) {
                this.notificationMethods.push({ address: 'SMS', type: 'SMS' });
            }
        }

        if (!this.notificationMethods.length) {
            this._settings.cssClass = 'send-message-to-customer--small';
        }
    }

    protected get smsCharacters() {
        return Utilities.getSmsLengthText(this.smsMessage);
    }
    protected get emailCharacters() {
        return Utilities.getEmailLengthText(this.emailMessage);
    }
    private getDefaultEmailMessage() {
        return (
            (this.messages && this.messages.email) ||
            (ApplicationState.messagingTemplates && ApplicationState.messagingTemplates.emailMessage) ||
            MessageTemplates.instance.emailMessage.default
        );
    }

    private getDefaultSMSMessage() {
        return (
            (this.messages && this.messages.sms) ||
            (ApplicationState.messagingTemplates && ApplicationState.messagingTemplates.smsMessage) ||
            MessageTemplates.instance.smsMessage.default
        );
    }

    private static canNotify(customer: Customer | Array<Customer>): boolean {
        if (!ApplicationState.isVerifiedForMessaging) return false;
        if (Array.isArray(customer)) return true;
        const canNotify =
            (!!customer.email && !!customer.email.trim().length) ||
            (!!customer.telephoneNumber && !!customer.telephoneNumber.trim().length) ||
            (!!customer.telephoneNumberOther && !!customer.telephoneNumberOther.trim().length);

        return canNotify;
    }

    protected insertCannedSmsResponse = () => {
        const { dialog, show } = AureliaReactComponentDialog.show({
            component: CannedResponsesDialog,
            componentProps: {
                cannedResponses: ApplicationState.getSetting<Array<CannedResponse>>('global.canned-responses', new Array<CannedResponse>()),
            },
            dialogTitle: 'settings.canned-responses-title',
            isSecondaryView: true,
        });

        show.then((result: string) => {
            if (dialog.cancelled || !result) return;

            const prompt = new Prompt('settings.canned-responses-insert-type', 'settings.canned-responses-insert-type-description', {
                okLabel: 'settings.canned-responses-insert-type-replace',
                altLabel: 'settings.canned-responses-insert-type-insert',
            });

            prompt.show().then(() => {
                if (prompt.cancelled) return;

                prompt.result.then(replace => {
                    this.smsMessage = replace ? result : this.smsMessage + result;
                });
            });
        });
    };

    public async init() {
        if (!ApplicationState.isVerifiedForMessaging) {
            new NotifyUserMessage('notification.account-email-not-verified-message-sending');
            this.cancel(true);
        }

        this.changedNotificationMethod();
        setTimeout(() => {
            <HTMLInputElement>document.getElementById('notification-message') &&
                (<HTMLInputElement>document.getElementById('notification-message')).focus();
            new ViewResizeEvent();
        }, 200);
    }

    private async previewMsg(scheduled?: boolean) {
        const customersToProcess = Array.isArray(this.customer) ? this.customer : [this.customer];

        if (customersToProcess.length === 0) return false;

        if (!this.notificationMethod) return false;

        const textField = document.getElementById(this.tinyMcePluginId) as HTMLTextAreaElement | HTMLInputElement;
        this.emailMessage = (textField && textField.value) || '';

        const templateText = (this.notificationMethod?.type === 'Email' ? this.emailMessage : this.smsMessage) || '';

        const useAddressFromNotificationMethod = customersToProcess.length === 1;
        const { description, message }: { to: string | undefined; description: string; message: string } = await this.getMessageDetails(
            customersToProcess[0],
            templateText,
            useAddressFromNotificationMethod
        );

        const confirm =
            this.notificationMethod?.type &&
            (await SendNotificationService.previewNotification(
                this.notificationMethod?.type,
                this.notificationMethod?.type === 'Email' ? message : description,
                undefined,
                scheduled
            ));
        if (!confirm) return false;

        let count = 0;
        this.notifications = {};

        const messageDetails = {} as Record<string, { to: string | undefined; description: string; message: string }>;
        for (const customer of customersToProcess) {
            const details = await this.getMessageDetails(customer, templateText, useAddressFromNotificationMethod);
            messageDetails[customer._id] = details;
        }

        if (this.notificationMethod?.type === 'SMS') {
            const enoughCredits = await checkHasEnoughCreditsToPublish(Object.values(messageDetails));
            if (!enoughCredits) return false;
        }

        new LoaderEvent(true, true, 'loader.sending-messages-to-customers', undefined, { customers: customersToProcess.length.toString() });
        await animate();

        const howMany = 250;
        while (customersToProcess.length) {
            const customers = customersToProcess.splice(0, howMany);
            const sending = [] as Array<Promise<Notification | undefined>>;

            for (const customer of customers) {
                const { to, description, message } = messageDetails[customer._id];
                if (!to) continue;

                sending.push(
                    SendNotificationService.send(
                        description,
                        to,
                        customer._id,
                        this.notificationMethod.type,
                        SendNotificationService.notificationFrom,
                        message,
                        ApplicationState.account.bccEmailNotifications,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        this.sendAtSecondsTimestamp
                    )
                );

                if (this.notificationMethod.type === 'Email') this.sent.email++;
                else if (this.notificationMethod.type === 'SMS') this.sent.sms++;
            }

            const notifications = await Promise.all(sending);
            for (const notification of notifications) {
                if (notification) this.notifications[notification.addressee] = notification;
            }

            count += notifications.length;

            new LoaderEvent(true, true, 'loader.sending-to-count-of-customers', undefined, {
                count: count.toString(),
                total: customersToProcess.length.toString(),
            });
            await animate(1);
        }
        new LoaderEvent(false);
        return true;
    }

    private async getMessageDetails(
        customer: Customer,
        templateText: string,
        useAddressFromNotificationMethod: boolean
    ): Promise<{ to: string; description: string; message: string }> {
        let description: string;

        const to =
            useAddressFromNotificationMethod && this.notificationMethod
                ? this.notificationMethod.address
                : this.notificationMethod && this.notificationMethod.type === 'Email'
                ? customer.email || ''
                : customer.telephoneNumber || customer.telephoneNumberOther || '';

        const isHtml = this.notificationMethod && this.notificationMethod.type === 'Email' && (!this.messages || this.messages.emailIsHtml);

        const messageModel =
            this.models?.[customer._id] || (await Utilities.getStandardMessageModel({ customer, isHtml: Boolean(isHtml), templateText }));
        let message: string;
        if (this.notificationMethod && this.notificationMethod.type === 'Email' && this.emailMessage) {
            const finalMessage = replaceMessageTokensWithModelValues({
                model: messageModel,
                message: this.emailMessage,
                options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
            });

            message = EmailTemplateService.getSimpleHtml(finalMessage);

            if (isHtml) {
                description = `Email Message: ${Utilities.extractTextFromHtml(finalMessage)}`;
            } else description = finalMessage;
        } else {
            const finalMessage = replaceMessageTokensWithModelValues({
                model: messageModel,
                message: this.smsMessage,
                options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
            });
            message = finalMessage;
            description = finalMessage;
        }
        return { to, description, message };
    }

    public sendAtSecondsTimestamp: number | undefined;

    public async onValidate(ok?: boolean): Promise<boolean | TranslationKey> {
        if (!ok) {
            this.sendAtSecondsTimestamp = await getSecondsTimestampForSchedule({
                options: {
                    isEmail: this.notificationMethod?.type === 'Email',
                },
            });
            if (!this.sendAtSecondsTimestamp) return false;
        }

        const textField = document.getElementById(this.tinyMcePluginId) as HTMLTextAreaElement | HTMLInputElement;
        this.emailMessage = (textField && textField.value) || '';

        if (
            (this.notificationMethod && this.notificationMethod.type === 'Email' && this.emailMessage && this.emailMessage.length) ||
            (this.notificationMethod && this.notificationMethod.type === 'SMS' && this.smsMessage && this.smsMessage.length)
        ) {
            const isHappyWithMsg = await this.previewMsg(!ok && this.sendAtSecondsTimestamp !== undefined);

            return isHappyWithMsg;
        } else return 'validation.message-required';
    }

    protected changedNotificationMethod() {
        if (!this.notificationMethod) this._settings.okLabel = '';
        else {
            this._settings.okLabel = this.notificationMethod.type !== 'SMS' || ApplicationState.account.smsAPI ? 'general.send' : '';
        }
    }
}
